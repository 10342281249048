import { VideoProcessorCmd } from './ProcessorEvents';
import {
  MediaStreamVideoProcessorAgent,
  VideoProcessorAgent,
} from './VideoProcessorAgent';

export class ProcessorManager {
  static videoProcessorAgentMap = new Map<string, VideoProcessorAgent>();
  static frameCallbackMap = new Map<string, (data: VideoFrame) => void>();
  static callbackMap = new Map<
    string,
    (cmd: VideoProcessorCmd, data: any) => void
  >();

  static hasProcessor() {
    return this.videoProcessorAgentMap.size > 0;
  }

  static hasMediaStreamFrameProcessor() {
    return [...this.videoProcessorAgentMap.values()].some(
      (value) => value instanceof MediaStreamVideoProcessorAgent
    );
  }

  static addFrameCallback(name: string, callback: (data: VideoFrame) => void) {
    this.frameCallbackMap.set(name, callback);

    if (this.getProcessor(name)) {
      this.getProcessor(name).addFrameCallback(callback);
    }
  }

  static addCallback(
    name: string,
    callback: (cmd: VideoProcessorCmd, data: any) => void
  ) {
    this.callbackMap.set(name, callback);

    if (this.getProcessor(name)) {
      this.getProcessor(name).addCallback(callback);
    }
  }

  static onFrame(frame: VideoFrame) {
    for (const processor of this.videoProcessorAgentMap.values()) {
      processor.onFrame(frame);
    }
  }

  static getStream() {
    const lastData = [...this.videoProcessorAgentMap.values()].pop();
    return lastData.getStream();
  }

  static setStream(stream: MediaStream) {
    for (const processor of this.videoProcessorAgentMap.values()) {
      processor.setStream(stream);
    }
  }

  static removeStream() {
    for (const processor of this.videoProcessorAgentMap.values()) {
      processor.removeStream();
    }
  }

  static getProcessor(name: string) {
    return this.videoProcessorAgentMap.get(name);
  }

  static startProcessor(name: string, processorAgent: VideoProcessorAgent) {
    processorAgent.init();
    const frameCallback = this.frameCallbackMap.get(name);
    if (frameCallback) {
      processorAgent.addFrameCallback(frameCallback);
    }
    const callback = this.callbackMap.get(name);
    if (callback) {
      processorAgent.addCallback(callback);
    }
    this.videoProcessorAgentMap.set(name, processorAgent);
  }

  static stopProcessor(name: string) {
    const processorAgent = this.getProcessor(name);
    if (processorAgent) {
      processorAgent.removeFrameCallback();
      processorAgent.removeCallback();
      this.frameCallbackMap.delete(name);
      this.callbackMap.delete(name);
      processorAgent.removeStream();
      processorAgent.uninit();
    }
    this.videoProcessorAgentMap.delete(name);
  }
  static resetProcessorManager() {
    for (const key of this.videoProcessorAgentMap.keys()) {
      this.stopProcessor(key);
    }
  }
}
