import { VideoProcessorCmd, VideoProcessorEvent } from './ProcessorEvents';
import {
  CanvasCaptureInputStream,
  InputStream,
  TrackProcessorInputStream,
} from './InputStream';
import {
  OutputStream,
  TrackGeneratorOutputStream,
  CanvasCaptureOutputStream,
} from './OutputStream';

interface VideoFrameAgent {
  onFrame(frame: VideoFrame): void;
  addFrameCallback(callback: (frame: VideoFrame) => void): void;
  removeFrameCallback(): void;
  addCallback(callback: (cmd: VideoProcessorCmd, data?: any) => void): void;
  removeCallback(): void;
}

interface MediaStreamAgent {
  getStream(): MediaStream;
  setStream(stream: MediaStream): void;
  removeStream(): void;
}

abstract class VideoProcessorAgent
  implements VideoFrameAgent, MediaStreamAgent
{
  protected frameCallback: ((frame: VideoFrame) => void) | null = null;
  protected callback: ((cmd: VideoProcessorCmd, data?: any) => void) | null =
    null;

  private onMessage = (e: MessageEvent) => {
    if (e.data.cmd === VideoProcessorEvent.FRAME_CALLBACK) {
      this.frameCallback?.(e.data.frame);
    } else {
      this.callback?.(e.data.cmd, e.data.data);
    }
  };
  constructor(
    protected processorName: string,
    protected port: MessagePort,
    protected isIOSDevice: boolean = false,
    protected VideoFrameNeeded: boolean = false
  ) {
    this.port.start();
    this.port.addEventListener('message', this.onMessage);
    const ofs = this.getOutPutCanvas();
    if (ofs) {
      this.port.postMessage(
        {
          cmd: VideoProcessorEvent.INIT_OUTPUT_CANVAS,
          canvas: ofs,
          processorName,
        },
        [ofs]
      );
    } else {
      this.port.postMessage({
        cmd: VideoProcessorEvent.INIT_OUTPUT_CANVAS,
        processorName,
      });
    }
  }
  init() {
    this.port.postMessage({
      cmd: VideoProcessorEvent.INIT_PROCESSOR,
      processorName: this.processorName,
    });
  }
  addCallback(callback: (cmd: VideoProcessorCmd, data?: any) => void): void {
    this.callback = callback;
  }
  removeCallback(): void {
    this.callback = null;
  }
  uninit() {
    this.port.postMessage({
      cmd: VideoProcessorEvent.UNINIT_PROCESSOR,
      processorName: this.processorName,
    });
    this.port.removeEventListener('message', this.onMessage);
  }
  getOutPutCanvas(): OffscreenCanvas | null {
    return null;
  }
  getStream(): MediaStream {
    throw new Error('Method not implemented.');
  }
  setStream(stream: MediaStream): void {
    throw new Error('Method not implemented.');
  }
  removeStream(): void {
    // throw new Error('Method not implemented.');
  }
  onFrame(frame: VideoFrame): void {
    throw new Error('Method not implemented.');
  }
  addFrameCallback(callback: (frame: VideoFrame) => void): void {
    throw new Error('Method not implemented.');
  }
  removeFrameCallback(): void {
    // throw new Error('Method not implemented.');
  }
}

export class VideoFrameVideoProcessorAgent
  extends VideoProcessorAgent
  implements VideoFrameAgent
{
  constructor(protected processorName: string, protected port: MessagePort) {
    super(processorName, port);
  }
  onFrame(frame: VideoFrame): void {
    this.port.postMessage({
      cmd: VideoProcessorEvent.FRAME_DATA,
      frame,
      processorName: this.processorName,
    });
  }
  addFrameCallback(callback: (frame: VideoFrame) => void) {
    this.frameCallback = callback;
  }
  removeFrameCallback() {
    this.frameCallback = null;
  }
}

export class ShadowVideoFrameProcessor extends VideoFrameVideoProcessorAgent {
  constructor(protected processorName: string, protected port: MessagePort) {
    super(processorName, port);
  }
  onFrame(frame: VideoFrame): void {}
}

export class MediaStreamVideoProcessorAgent
  extends VideoProcessorAgent
  implements MediaStreamAgent
{
  private originGenerator: InputStream;
  private targetGenerator: OutputStream;
  constructor(
    protected processorName: string,
    protected port: MessagePort,
    protected isIOSDevice: boolean,
    protected VideoFrameNeeded: boolean
  ) {
    super(processorName, port, isIOSDevice, VideoFrameNeeded);
    this.initGenerator();
  }
  initGenerator() {
    if (!this.originGenerator) {
      this.originGenerator =
        typeof MediaStreamTrackProcessor === 'function'
          ? new TrackProcessorInputStream(
              this.port,
              this.processorName,
              this.VideoFrameNeeded
            )
          : new CanvasCaptureInputStream(
              this.port,
              this.processorName,
              this.isIOSDevice,
              this.VideoFrameNeeded
            );
    }
    if (!this.targetGenerator) {
      this.targetGenerator =
        typeof MediaStreamTrackGenerator === 'function'
          ? new TrackGeneratorOutputStream(this.port, this.processorName)
          : new CanvasCaptureOutputStream(this.port, this.processorName);
    }
  }
  uninit(): void {
    this.originGenerator?.destroy?.();
    this.targetGenerator?.destroy?.();
    super.uninit();
  }
  getOutPutCanvas(): OffscreenCanvas | null {
    this.initGenerator();
    return this.targetGenerator.getOutputCanvas?.();
  }
  getStream() {
    return this.targetGenerator.getStream();
  }
  setStream(stream: MediaStream): void {
    this.originGenerator.setStream(stream);
  }
  removeStream(): void {
    this.originGenerator.removeStream();
  }
  addFrameCallback(callback: (frame: VideoFrame) => void) {
    this.frameCallback = callback;
  }
}

export type { VideoProcessorAgent };
