import { VideoProcessorEvent } from './ProcessorEvents';

export interface OutputStream {
  getStream(): MediaStream;
  getOutputCanvas?(): OffscreenCanvas;
  destroy?(): void;
}

export class TrackGeneratorOutputStream implements OutputStream {
  private trackGenerator: MediaStreamTrackGenerator<VideoFrame>;
  private stream: MediaStream;
  constructor(port: MessagePort, name: string) {
    this.trackGenerator = new MediaStreamTrackGenerator({
      kind: 'video',
    });
    const writableStream = this.trackGenerator.writable;
    port.postMessage(
      {
        cmd: VideoProcessorEvent.OUTPUT_WRITABLE_STREAM,
        writableStream,
        processorName: name,
      },
      [writableStream]
    );
    this.stream = new MediaStream([this.trackGenerator]);
  }
  getStream() {
    return this.stream;
  }
}

export class CanvasCaptureOutputStream implements OutputStream {
  private canvas: HTMLCanvasElement;
  private stream: MediaStream;
  private offScreenCanvas: OffscreenCanvas;
  constructor(port: MessagePort, name: string) {
    this.canvas = document.createElement('canvas');
    this.canvas.width = 1;
    this.canvas.height = 1;
    this.offScreenCanvas = this.canvas.transferControlToOffscreen();
    this.stream = this.canvas.captureStream(24);
  }
  getOutputCanvas() {
    return this.offScreenCanvas;
  }
  getStream() {
    return this.stream;
  }
}
