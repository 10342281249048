export enum VideoProcessorWorkerEvent {
  ADD_VIDEO_PORT = 'add_video_port',
  ADD_SHADOW_PORT = 'add_shadow_port',
  ADD_VIDEO_PROCESSOR = 'add_video_processor',

  PROCESSOR_READY = 'processor_ready',
  PROCESSOR_ERROR = 'processor_error',
}

export enum VideoProcessorEvent {
  INIT_PROCESSOR = 'init_processor',
  UNINIT_PROCESSOR = 'uninit_processor',
  INIT_OUTPUT_CANVAS = 'init_output_canvas',
  OUTPUT_WRITABLE_STREAM = 'output_writable_stream',
  INPUT_READABLE_STREAM = 'input_readable_stream',
  FRAME_DATA = 'frame_data',
  START_REQUEST_FRAME = 'start_request_frame',
  STOP_REQUEST_FRAME = 'stop_request_frame',

  REQUEST_FRAME = 'request_frame',
  FRAME_CALLBACK = 'frame_callback',
  RESOLUTION_CHANGE = 'RESOLUTION_CHANGE',
  PROCESSOR_WORKER_ERROR = 'PROCESSOR_WORKER_ERROR',
}

export enum VideoProcessorCmd {}
