import wasmFeatureDetect from '../common/detectWasmFeatures';
import JsMediaSDK_WaterMarkRGBA from '../inside/JsMediaSDK_WaterMark';
import globalTracingLogger from '../common/globalTracingLogger';
import jsMediaEngineVariables from '../inside/JsMediaEngine_Variables';
import * as RenderConst from '../common/renderer/RenderConst';
import Zoom_Monitor from '../inside/Monitor';
import UAParser from 'ua-parser-js';
import { screenWakeLock } from './ScreenWakeLock';
import MediaConfigParser from '../common/MediaConfigParser';
import { PersistenceInfo } from './persistenceInfo';
import ABOptionsReader from './ABOptionsReader';
import { BIT_MAPPING } from './ABOptionsDefinition';

const downloadUrlMapPromise = {};
const DISABLE_AUDIOBRIDGE = 0;
const ENABLE_AUDIOBRIDGE = 1;
let isWebRTCMode = false;
let check32bitChrome = false;
const MSFTMode = 'MSFT';

const getBrowserVersionInfo = (function () {
  let getBrowserVersion = () => {
    var Sys = {};
    var ua = navigator.userAgent.toLowerCase();
    var s;
    (s = ua.match(/rv:([\d.]+)\) like gecko/))
      ? ((Sys.ie = s[1]), (Sys.name = 'ie'))
      : (s = ua.match(/msie ([\d\.]+)/))
      ? ((Sys.ie = s[1]), (Sys.name = 'ie'))
      : (s = ua.match(/(?:edge|edg)\/([\d\.]+)/))
      ? ((Sys.edge = s[1]), (Sys.name = 'edge'))
      : (s = ua.match(/(?:firefox|iceweasel)\/([\d\.]+)/))
      ? ((Sys.firefox = s[1]), (Sys.name = 'firefox'))
      : (s = ua.match(/(?:opera|opr).([\d\.]+)/))
      ? ((Sys.opera = s[1]), (Sys.name = 'opera'))
      : (s = ua.match(/chrome\/([\d\.]+)/))
      ? ((Sys.chrome = s[1]), (Sys.name = 'chrome'))
      : (s = ua.match(/version\/([\d\.]+).*safari/))
      ? ((Sys.safari = s[1]), (Sys.name = 'safari'))
      : 0;
    return Sys;
  };

  let broswerinfo = getBrowserVersion();
  // update broswerinfo in unit test
  if (__TEST__) {
    window.updateBrowserVersion = () => {
      broswerinfo = getBrowserVersion();
    };
  }

  return function () {
    return broswerinfo;
  };
})();

const getOSVersionInfo = (function () {
  let Sys = {};
  const getInfo = function () {
    try {
      let result = UAParser(navigator.userAgent);
      let bTablet = result?.device?.type == 'tablet' || isTablet();
      let bMobile = result?.device?.type == 'mobile' || isMobileDevice();
      let os = result?.os?.name;

      let version = result?.os?.version;

      let deviceType = 0; // 'Desktop';
      if (bTablet) {
        deviceType = 4; // 'Tablet';
      } else if (bMobile) {
        deviceType = 1; // 'Mobile';
      }

      if (!os) {
        const match = navigator.userAgent.match(
          /\(([^;]+); OpenHarmony ([\d.]+)\)/
        );
        if (match && match.length > 2) {
          os = 'OpenHarmony';
          version = match[2];
          if (match[1] === 'Tablet') {
            deviceType = 4; // 'Tablet';
          } else if (match[1] === 'Phone') {
            deviceType = 1; // 'Mobile';
          }
        }
      }
      Sys.osType = deviceType;
      Sys.os = os;
      Sys.osVersion = version;
      Sys.browser = Object.assign({}, result.browser);
      if (os?.toLowerCase().includes('mac') && (bTablet || bMobile)) {
        Sys.os = 'ios';
      }

      if (os?.toLowerCase().includes('linux') && (bTablet || bMobile)) {
        Sys.os = 'android';
      }

      let majorVersion = (version || '').split('.')[0];

      if (
        os &&
        navigator.userAgentData &&
        ((os.toLowerCase().includes('win') && majorVersion == '10') ||
          os.toLowerCase().includes('android') ||
          os.toLowerCase().includes('mac'))
      ) {
        navigator.userAgentData
          .getHighEntropyValues(['platform', 'platformVersion'])
          .then((values) => {
            os = values?.platform;
            const platform = (os || '').toLowerCase();

            const majorPlatformVersion = parseInt(
              (values.platformVersion || '').split('.')[0]
            );

            if (platform.includes('win')) {
              if (majorPlatformVersion >= 13) {
                version = '11';
              } else if (majorPlatformVersion > 0) {
                version = '10';
              } else {
                version = '7';
              }

              Sys.os = os;
              Sys.osVersion = version;
            } else if (platform.includes('android')) {
              version = values.platformVersion;
              Sys.osVersion = version;
            } else if (platform.includes('mac')) {
              version = values.platformVersion;
              Sys.osVersion = version;
            }
          });
      }
    } catch (e) {}
  };

  getInfo();

  if (__TEST__) {
    window.updateOSVersion = () => {
      getInfo();
    };
  }

  return function () {
    return Sys;
  };
})();
/**
 * browser: safari | firefox | chrome | chromium
 */

const versionCompare = (browserVersion, version) => {
  const versionArr = version.toString().split('.');
  const bVersionArr = browserVersion.toString().split('.');
  const vLen = versionArr.length;
  const bvLen = bVersionArr.length;
  const len = Math.min(vLen, bvLen);
  for (let i = 0; i < len; i++) {
    const v = parseInt(versionArr[i], 10);
    const bv = parseInt(bVersionArr[i], 10);
    if (v !== bv) return bv > v;
  }
  return bvLen >= vLen;
};

const isBrowserVersionHigherThanOrEqualTo = (browser, version) => {
  var Sys = getBrowserVersionInfo();
  if (browser === 'chrome' || browser === 'chromium') {
    if (Sys.chrome || Sys.edge || Sys.opera) {
      var chromimuVersion = Sys.chrome || Sys.edge || Sys.opera;
      return versionCompare(chromimuVersion, version);
    }
  } else {
    if (Sys[browser]) {
      return versionCompare(Sys[browser], version);
    }
  }
  return false;
};

/**
 * @description if broswer is chrome and broswer version higher than a target version
 */
const isChromeVersionHigherThan = (version = 90) => {
  return isBrowserVersionHigherThanOrEqualTo('chrome', version);
};

/**
 * @description if broswer is chrome and broswer version higher than a target version
 */

const isFirefoxVersionHigherThan = (version) => {
  return isBrowserVersionHigherThanOrEqualTo('firefox', version);
};

const isSafariVersionHigherThan = (version) => {
  return isBrowserVersionHigherThanOrEqualTo('safari', version);
};

//Edge us edg as userAgent
const isChromimuVersionHigherThan = (version = 90) => {
  return isBrowserVersionHigherThanOrEqualTo('chromium', version);
};

function isSupportMediaStreamTrackProcessor() {
  return (
    typeof MediaStreamTrackProcessor === 'function' &&
    IsSupportWebGLOffscreenCanvas()
  );
}

class _ApiSupportUtility {
  constructor() {
    this._isSupportMultiThread = false;
    this._isSupportSIMD = false;
    this.inProgressPromise = {
      checkSupportMultiThread: null,
      checkSupportSIMD: null,
    };
    /** webtransport switch */
    this._isSupportWebtransport = false;
    /** virtual background switch */
    this._isSupportVirtualBackground = false;
  }

  async checkIsSupportMultiThread() {
    if (this.getIsSupportMultiThread()) return;
    try {
      if (this.inProgressPromise.checkSupportMultiThread) {
        await this.inProgressPromise.checkSupportMultiThread;
      } else {
        this.inProgressPromise.checkSupportMultiThread =
          wasmFeatureDetect.threads();
        this._setIsSupportMultiThread(
          await this.inProgressPromise.checkSupportMultiThread
        );
      }
    } catch (e) {
      this._setIsSupportMultiThread(false);
    }
  }

  _setIsSupportMultiThread(bool) {
    this._isSupportMultiThread = bool;
  }

  getIsSupportMultiThread() {
    return this._isSupportMultiThread;
  }

  async checkIsSupportSIMD() {
    if (this.getIsSupportSIMD()) return;
    try {
      if (this.inProgressPromise.checkSupportSIMD) {
        await this.inProgressPromise.checkSupportSIMD;
      } else {
        this.inProgressPromise.checkSupportSIMD = wasmFeatureDetect.simd();
        this._setIsSupportSIMD(await this.inProgressPromise.checkSupportSIMD);
      }
    } catch (e) {
      this._setIsSupportSIMD(false);
    }
  }

  _setIsSupportSIMD(bool) {
    this._isSupportSIMD = bool;
  }

  getIsSupportSIMD() {
    return this._isSupportSIMD;
  }

  getIsSupportWebtransport() {
    return (
      isChromeVersionHigherThan(97) &&
      typeof WebTransport === 'function' &&
      this._isSupportWebtransport
    );
  }

  setIsSupportWebtransport(bool) {
    this._isSupportWebtransport = !!bool;
  }

  getIsSupportVirtualBackground() {
    const isBrowserSupport =
      isChromeVersionHigherThan(91) ||
      isFirefoxVersionHigherThan(89) ||
      isSafariVersionHigherThan(17.4);
    return (
      navigator.hardwareConcurrency &&
      navigator.hardwareConcurrency > 2 &&
      //vb only support on high version of chrome or firefox
      isBrowserSupport &&
      typeof OffscreenCanvas == 'function' &&
      this._isSupportVirtualBackground
    );
  }

  setIsSupportVirtualBackground(bool) {
    this._isSupportVirtualBackground = !!bool;
  }

  /**
   * @description if render self video in the encode worker, other than in the decode worker or main thread.
   */
  getIsRenderSelfVideoInEncodeWorker(isSelfVideo = false) {
    return (
      !jsMediaEngineVariables.enableMultiDecodeVideoWithoutSAB &&
      isSelfVideo &&
      !this.getIsSupportMultiThread() &&
      this.getIsSupportVirtualBackground()
    );
  }
}
export const apiSupportUtility = new _ApiSupportUtility();

var HW720PEncoderCapacity = {
  capacity: undefined,
  capacity1080: true,
  get capacityfor720() {
    return this.capacity === undefined ? false : this.capacity;
  },
  set capacityfor720(ca) {
    this.capacity = ca;
  },
  get capacityfor1080() {
    return this.capacity1080 === undefined ? false : this.capacity1080;
  },
  set capacityfor1080(ca) {
    this.capacity1080 = ca;
  },
};

var HW1080PDeocderCapacity = {
  capacitydeco: undefined,
  get capacitydecofor1080() {
    return this.capacitydeco === undefined ? false : this.capacitydeco;
  },
  set capacitydecofor1080(ca) {
    this.capacitydeco = ca;
  },
};

function extractVersion(uastring, expr, pos) {
  const match = uastring.match(expr);
  return match && match.length >= pos && parseInt(match[pos], 10);
}

function detectBrowser() {
  const { navigator } = window;

  // Returned result object.
  const result = { browser: null, version: null };

  // Fail early if it's not a browser
  if (typeof window === 'undefined' || !window.navigator) {
    result.browser = 'Not a browser.';
    return result;
  }

  if (navigator.mozGetUserMedia) {
    // Firefox.
    result.browser = 'firefox';
    result.version = extractVersion(navigator.userAgent, /Firefox\/(\d+)\./, 1);
  } else if (navigator.webkitGetUserMedia) {
    // Chrome, Chromium, Webview, Opera.
    // Version matches Chrome/WebRTC version.
    result.browser = 'chrome';
    result.version = extractVersion(
      navigator.userAgent,
      /Chrom(e|ium)\/(\d+)\./,
      2
    );
  } else if (
    navigator.mediaDevices &&
    navigator.userAgent.match(/Edge\/(\d+).(\d+)$/)
  ) {
    // Edge.
    result.browser = 'edge';
    result.version = extractVersion(
      navigator.userAgent,
      /Edge\/(\d+).(\d+)$/,
      2
    );
  } else if (
    window.RTCPeerConnection &&
    navigator.userAgent.match(/AppleWebKit\/(\d+)\./)
  ) {
    // Safari.
    result.browser = 'safari';
    result.version = extractVersion(
      navigator.userAgent,
      /AppleWebKit\/(\d+)\./,
      1
    );
  } else {
    // Default fallthrough: not supported.
    result.browser = 'Not a supported browser.';
    return result;
  }

  return result;
}
const browserType = detectBrowser();

function getBrowserInfo() {
  var agent = navigator.userAgent.toLowerCase();
  var regStr_ff = /firefox\/[\d.]+/gi;
  var regStr_chrome = /chrome\/[\d.]+/gi;
  var regStrChrome2 = /ipad; cpu os (\d+_\d+)/gi;
  var regStr_saf = /safari\/[\d.]+/gi;
  var regStr_saf2 = /safari\/[\d.]+/gi;
  var regStr_edg = /edg\/[\d.]+/gi;

  // firefox
  if (agent.indexOf('firefox') > 0) {
    return agent.match(regStr_ff);
  }

  // Safari
  if (agent.indexOf('safari') > 0 && agent.indexOf('chrome') < 0) {
    var tmpInfo = 'safari/unknow';
    var tmpInfo2;
    tmpInfo = agent.match(regStr_saf);
    tmpInfo2 = agent.match(regStr_saf2);
    if (tmpInfo) {
      tmpInfo = [tmpInfo.toString().replace('version', 'safari')];
    }
    if (tmpInfo2) {
      tmpInfo = [tmpInfo2.toString().replace('version', 'safari')];
    }
    return tmpInfo;
  }

  // Chrome
  if (agent.indexOf('chrome') > 0) {
    return agent.match(regStr_chrome);
  }

  return 'other';
}

function DetectQuestBrowser() {
  try {
    return /oculusbrowser/i.test(navigator.userAgent);
  } catch (e) {
    return false;
  }
}

function DetectAndroidBrowser() {
  try {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
function DetectisOpera65() {
  try {
    var tem = navigator.userAgent.match(/OPR\/(\d+)\./);
    if (tem && Number(tem[1]) < 66) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

function DetectChromeOS() {
  try {
    if (/\bCrOS\b/.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

function DetectIsSupportImageCapture() {
  return typeof ImageCapture === 'function';
}

const globalRenderInfo = (function () {
  let attrs = {
    vendor: '',
    renderInfo: '',
    isAstcSupported: false,
    isWebGLContextInvalid: true,
  };

  try {
    const canvas = document.createElement('canvas');

    if (!canvas) {
      const errorMsg = 'Error: document.createElement return a null canvas!';
      globalTracingLogger.error(errorMsg);
    }
    let context =
      canvas.getContext('webgl') ||
      canvas.getContext('moz-webgl') ||
      canvas.getContext('webkit-3d') ||
      canvas.getContext('experimental-webgl');

    if (!context || context.isContextLost()) {
      const errorMsg = `Error: canvas.getContext fail, context:${context}, lost:${context?.isContextLost()}, size:(${
        canvas.width
      },${canvas.height})`;
      globalTracingLogger.error(errorMsg);
    }

    if (context) {
      attrs.isWebGLContextInvalid = false;
      if (context.isContextLost()) {
        const errorMsg = `Error: webgl context is lost, canvas(${canvas.width},${canvas.height})!`;
        globalTracingLogger.error(errorMsg);
      }
      let renderer;
      let vendor;
      if (browserType.browser === 'firefox') {
        renderer = context.getParameter(context.RENDERER);
        vendor = context.getParameter(context.VENDOR);
      } else {
        let debugInfo = context.getExtension('WEBGL_debug_renderer_info');
        renderer = context.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        vendor = context.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
      }
      attrs.renderInfo = renderer?.toLowerCase();
      attrs.vendor = vendor?.toLowerCase();
      attrs.isAstcSupported =
        context
          .getSupportedExtensions()
          .indexOf('WEBGL_compressed_texture_astc') !== -1;
      if (vendor == '') {
        const errorMsg = `Error: vendor is null, debug:${debugInfo}, render:${
          debugInfo.UNMASKED_RENDERER_WEBGL
        }, vendor:${
          debugInfo.UNMASKED_VENDOR_WEBGL
        }, contextLost:${context.isContextLost()}!`;
        globalTracingLogger.error(errorMsg);
      }
    }
  } catch (e) {
    globalTracingLogger.error('Error while get webgl context:', e);
  }

  return attrs;
})();

const LocalIsSupportWebGLOffscreenCanvas =
  (function LocalIsSupportWebGLOffscreenCanvas() {
    if (typeof OffscreenCanvas !== 'function') {
      return false;
    }
    let webGLAttributes = {
      isSupportWebgl: false,
      isSupportWebgl2: false,
    };
    try {
      const ofs = new OffscreenCanvas(1, 1);
      let gl =
        ofs.getContext('webgl') ||
        ofs.getContext('experimental-webgl') ||
        ofs.getContext('moz-webgl') ||
        ofs.getContext('webkit-3d');
      if (gl) {
        webGLAttributes.isSupportWebgl = true;
      }
    } catch (e) {
      globalTracingLogger.error('Error while get webglcontext:', e);
    }

    try {
      if (webGLAttributes.isSupportWebgl) {
        const ofs = new OffscreenCanvas(1, 1);
        let gl = ofs.getContext('webgl2');
        if (gl) {
          webGLAttributes.isSupportWebgl2 = true;
        }
      }
    } catch (e) {
      globalTracingLogger.error('Error while get webglcontext2:', e);
    }

    return webGLAttributes;
  })();
var machineInfo = (function () {
  let machineInfo = Object.assign(
    {},
    LocalIsSupportWebGLOffscreenCanvas,
    globalRenderInfo
  );
  machineInfo.cpu = navigator.hardwareConcurrency
    ? navigator.hardwareConcurrency
    : 0;
  machineInfo.VE = 0;
  machineInfo.SE = 0;
  machineInfo.VD = 0;
  machineInfo.SD = 0;
  machineInfo.SAB = typeof SharedArrayBuffer != undefined;
  machineInfo.errorMessage = null;
  machineInfo.visibility = true;
  machineInfo.unvisibilitycount = 0;
  machineInfo.time = 0;
  return machineInfo;
})();
export function IsSupportWebGLOffscreenCanvas() {
  return LocalIsSupportWebGLOffscreenCanvas.isSupportWebgl;
}

export function DetectIsMTRAndroidWithSAB() {
  return (
    navigator.userAgent.indexOf('MSFT Teams Android Room') !== -1 &&
    typeof SharedArrayBuffer !== 'undefined'
  );
}

function DetectisSupportVideoTrackReader() {
  return (
    typeof VideoTrackReader === 'function' && typeof VideoFrame == 'function'
  );
}

export function Get_Logical_SSrc(ssrc) {
  if (ssrc) {
    return (ssrc >> 10) << 10;
  }
  return -1;
}

export function createMainAudioContext() {
  let audioContextConfigure = util.getAudioContextConfigure();
  return createAudioContext('Main', audioContextConfigure);
}

export function createAudioContext(label, configuration = null) {
  if (typeof AudioContext !== 'function') {
    globalTracingLogger.error(`Not Support AudioContext`);
    return null;
  }
  globalTracingLogger.log(`Creating ${label} AudioContext`);
  let audioContext;
  if (configuration) {
    audioContext = new AudioContext(configuration);
  } else {
    audioContext = new AudioContext();
  }

  audioContext.onstatechange = () => {
    globalTracingLogger.log(
      `${label} AudioContext state changed to ${audioContext.state}`
    );
    Zoom_Monitor.add_monitor(`ACSC:${label}:${audioContext.state}`);
  };

  audioContext.onsinkchange = () => {
    if (
      typeof audioContext.sinkId === 'object' &&
      audioContext.sinkId.type === 'none'
    ) {
      globalTracingLogger.log(
        `${label} AudioContext - Audio changed to not play on any device`
      );
    } else {
      globalTracingLogger.log(
        `${label} AudioContext - Audio output device changed to ${audioContext.sinkId}`
      );
    }
  };

  return audioContext;
}

const util = {
  getOSInfo() {
    return getOSVersionInfo();
  },

  getGpuInfo() {
    return globalRenderInfo;
  },

  isMTRAndroidWithSAB() {
    return DetectIsMTRAndroidWithSAB();
  },
  /**
   * @returns {Promise<boolean>} check if local p2p connection is supported
   */
  isSupport2DCanvasDrawFrame() {
    /**
     * Mac chrome 2d context drawing video frame have bug when camera can only capture 720p video
     * when set 640x360 para to constraints, ctx.drawImage(videoframe,0,0,width,height)
     * will only draw 1/4 of captued video, and 2d context drawing video frame will crush
     * if memory is not enough
     *
     * Originally for Google Nest (depricated)
     * */
    return (
      (typeof MediaStreamTrackProcessor === 'function' ||
        DetectisSupportVideoTrackReader()) &&
      browserType.browser == 'chrome' &&
      browserType.version >= 104 &&
      navigator.appVersion.indexOf('Mac') == -1
    );
  },

  checkLocalP2PConnection() {
    return new Promise(async (resolve, reject) => {
      function cp(rtcIns) {
        return new Promise((resolve, reject) => {
          rtcIns.onconnectionstatechange = (state) => {
            if (rtcIns.connectionState === 'connected') {
              resolve(true);
            }
          };
        });
      }

      function close(rtcIns) {
        if (rtcIns) {
          rtcIns.close();
        }
      }

      let rtcA = null;
      let rtcB = null;
      let canvas = document.createElement('canvas');
      let offer, answer;

      try {
        canvas.width = 200;
        canvas.height = 200;
        canvas.getContext('2d'); // for firefox
        let stream = canvas.captureStream();

        if (typeof RTCPeerConnection !== 'function') {
          // very old browser
          resolve(false);
          return;
        }

        rtcA = new RTCPeerConnection();
        rtcB = new RTCPeerConnection();

        rtcA.onicecandidate = (e) => {
          e.candidate && rtcB.addIceCandidate(new RTCIceCandidate(e.candidate));
        };

        rtcB.onicecandidate = (e) => {
          e.candidate && rtcA.addIceCandidate(new RTCIceCandidate(e.candidate));
        };

        Promise.all([cp(rtcA), cp(rtcB)]).then(() => {
          // Test result : local P2P connection will be created after 1 ~ 15 ms, it depends on the user's computer/browser
          // Chrome is fastest : 1ms
          // Edge(chromium) : 8ms
          resolve(true);
          close(rtcA);
          close(rtcB);
        });

        if (typeof rtcA.addStream !== 'function') {
          // Safari doesn't implement addStream
          resolve(false);
          return;
        }

        await rtcA.addStream(stream);
        offer = await rtcA.createOffer();
        await rtcA.setLocalDescription(offer);
        await rtcB.setRemoteDescription(offer);
        answer = await rtcB.createAnswer();
        await rtcB.setLocalDescription(answer);
        await rtcA.setRemoteDescription(answer);

        setTimeout(() => {
          resolve(false); // not supported local P2P rtc peer connection
          close(rtcA);
          close(rtcB);
          // the settimeout is not stable, add from 100ms to 200ms temparary
        }, 200);
      } catch (e) {
        globalTracingLogger.error(
          'Error when trying to check for local RTC peer connection',
          e
        );
        resolve(false);
      }
    });
  },
  getDocumentHandle(id) {
    return document.getElementById(id);
  },
  /**
   * There is no 100% reliable browser detection
   * Better solution is 'browser feature detection'
   */
  browserType,
  browser: {
    isFirefox: browserType.browser === 'firefox',
    isChrome: browserType.browser === 'chrome',
    isSafari: browserType.browser === 'safari',
  },
  isIphoneOrIpadSafari() {
    return this.isIphoneOrIpadBrowser() || this.isIpadOS();
  },
  isOpera65() {
    return DetectisOpera65();
  },
  isOpera() {
    return /opera|opr\/[\d]+/i.test(navigator.userAgent);
  },
  isOperaVersionHigherThan(version) {
    try {
      var tem = navigator.userAgent.match(/OPR\/(\d+)\./);
      if (tem && Number(tem[1]) >= version) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },
  isAndroidBrowser() {
    return DetectAndroidBrowser();
  },
  isAndroidVersionLessEqual(version) {
    const Sys = getOSVersionInfo();
    if (!Sys.os || Sys.os.toLowerCase() !== 'android' || !Sys.osVersion) {
      return false;
    }

    const versionArr = Sys.osVersion.toString().split('.');
    const mainVersion = parseInt(versionArr[0]);
    return mainVersion <= version;
  },
  isSupportVideoFrame() {
    return typeof VideoFrame != 'undefined';
  },
  isQuestBrowser() {
    return DetectQuestBrowser();
  },
  isMobileSafariSupportVideoFrame() {
    return (
      (this.isIphoneOrIpadBrowser() ||
        (this.isIpadOS() && jsMediaEngineVariables.rwgAgent)) &&
      this.isSupportVideoFrame()
    );
  },

  isSelfPreviewRenderWithVideo() {
    if (this.isMobileSafariSupportVideoFrame()) {
      return true;
    }
    return (
      (!apiSupportUtility.getIsSupportMultiThread() &&
        !apiSupportUtility.getIsSupportVirtualBackground() &&
        this.isSupportVideoFrameOrBitmapCapture()) ||
      /** enable multithread in android for video-sdk */
      (this.isAndroidBrowser() &&
        !this.isMTRAndroidWithSAB() &&
        !jsMediaEngineVariables.rwgAgent)
    );
  },

  isSupportNewWaitRoomFlow() {
    return true;
  },

  isMacIntelSafari() {
    try {
      /**
       * use touchpoint for skip ipad on desktop client
       * use !isAstcSupported to check intel safari
       */
      if (
        this.browser.isSafari &&
        (!navigator.maxTouchPoints || navigator.maxTouchPoints <= 2) &&
        !globalRenderInfo.isAstcSupported &&
        this.isMac()
      ) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },
  isMacIntelChrome() {
    try {
      if (
        this.browser.isChrome &&
        (!navigator.maxTouchPoints || navigator.maxTouchPoints <= 2) &&
        globalRenderInfo.vendor?.indexOf('intel') > -1 &&
        this.isMac()
      ) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },
  isIphoneOrIpadBrowser() {
    try {
      if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },
  isIpadOS() {
    return (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      (/iPad/.test(navigator.platform) || /MacIntel/.test(navigator.platform))
    );
  },
  isChromeOS() {
    return DetectChromeOS();
  },
  isWindowsChrome() {
    const { userAgent } = navigator;
    return /windows/i.test(userAgent);
  },
  isTeslaMode() {
    return /TESLA/.test(navigator.userAgent);
  },
  isMac() {
    return navigator.platform.indexOf('Mac') > -1;
  },
  isWindows() {
    return navigator.platform.indexOf('Win') > -1;
  },
  //ChromeOS is a linux-based operating system
  isLinux() {
    return navigator.platform.indexOf('Linux') > -1 && !this.isChromeOS();
  },
  isMTRAndroid() {
    return /MSFT Teams Android Room/i.test(navigator.userAgent);
  },
  isMTRWindows() {
    const controller = window.meetingHost;
    if (!controller) {
      return false;
    }

    let vendorId = controller.getHostState().vendorId;
    if (vendorId !== MSFTMode) {
      return false;
    }
    return !this.isMTRAndroid();
  },

  //edge is supported as chrome
  isSupportChromeWideAEC() {
    const isChromeOSSupportChromeWideAEC =
      jsMediaEngineVariables.chromeWideAEC &&
      this.isChromeOS() &&
      isChromeVersionHigherThan(116);
    return (
      ((this.isMac() || this.isWindows() || this.isLinux()) &&
        isChromeVersionHigherThan(111)) ||
      isChromeOSSupportChromeWideAEC ||
      jsMediaEngineVariables.isGoogleMeetMode
    );
  },

  isSupportOpenMicWhenShareAudio() {
    return (
      this.isSupportChromeWideAEC() && !jsMediaEngineVariables.shareSystemAudio
    );
  },

  /**
   * @description: Report Support Audio Feature to MMR
   * @return {int}
   */
  // return uint32
  getAudioFeatureFlags() {
    // !! important same as common defines
    const SUPPORT_FEATURE_NONE = 0x00000000;
    const SUPPORT_FEATURE_OPUS_ENCODE = 0x00000001 << 2; // both 5128 wcl and audiobridge supported
    const SUPPORT_FEATURE_LOW_LATENCY_DECODE = 0x00000001 << 3; // audio bridge not supported
    //TODO
    // old WCL : return SUPPORT_FEATURE_NONE
    // new WCL : return SUPPORT_FEATURE_OPUS_ENCODE | SUPPORT_FEATURE_LOW_LATENCY_DECODE
    // auiobridge: SUPPORT_FEATURE_OPUS_ENCODE
    if (jsMediaEngineVariables.enableAudioBridge)
      return SUPPORT_FEATURE_OPUS_ENCODE;
    else
      return SUPPORT_FEATURE_OPUS_ENCODE | SUPPORT_FEATURE_LOW_LATENCY_DECODE;
  },
  isSupportShareMultiStream() {
    return true;
  },
  isSupportVideoLTR() {
    return true;
  },
  isSupportAudioBridgeAvsync() {
    return true;
  },
  getAudioContextConfigure() {
    let audioContextConfigure = {};
    let latencyHint = 0.02;
    if (isChromimuVersionHigherThan(74)) {
      if (false && navigator.hardwareConcurrency >= 4) {
        latencyHint = 0.01;
      }
      audioContextConfigure = {
        sampleRate: 48000,
        latencyHint: latencyHint,
      };
    }
    return audioContextConfigure;
  },
  /**
   * @param url {string}
   * @param integrity {string}
   * @returns {Promise}
   */
  download(url, integrity = null) {
    if (downloadUrlMapPromise[url]) {
      return downloadUrlMapPromise[url];
    }
    let promise = new Promise(async (resolve, reject) => {
      let initParams = {};
      if (integrity) {
        initParams.integrity = integrity;
      }
      const retryLimit = 3;
      for (let i = 0; i < retryLimit; i++) {
        try {
          let response = await fetch(url, initParams);
          if (response?.ok) {
            resolve(response.text());
            if (i > 0) {
              globalTracingLogger.error(
                `after ${i} retry download js ${url} successed `
              );
            }
            break;
          }
          if (retryLimit - 1 == i) {
            reject(`download failed ${url} ${response?.status}`);
          }
        } catch (ex) {
          if (retryLimit - 1 == i) {
            reject(ex);
          }
        }
      }
    });
    downloadUrlMapPromise[url] = promise;
    return promise;
  },
  async downloadAndCompileWebAssembly(
    wasmUrl,
    workerType,
    monitor,
    useStreaming = true
  ) {
    // Ensure that browser does not initiate multiple requests for the same asset.
    const key = `${wasmUrl}${useStreaming}`;
    if (downloadUrlMapPromise[key]) {
      return downloadUrlMapPromise[key];
    }

    let promise = new Promise(async (resolve, reject) => {
      let moduleOrArrayBuffer = null;

      const retryLimit = 3;
      monitor.add_monitor(`CS${workerType}`);
      for (let i = 0; i < retryLimit; i++) {
        try {
          if (useStreaming) {
            moduleOrArrayBuffer = await WebAssembly.compileStreaming(
              fetch(wasmUrl, { priority: 'high' })
            );
          } else {
            const response = await fetch(wasmUrl, { priority: 'high' });
            const arrayBuffer = await response.arrayBuffer();
            moduleOrArrayBuffer = arrayBuffer;
          }

          monitor.add_monitor(`CE${workerType}`);
        } catch (e) {
          // The content-type header may not be set to application/wasm, so try regular compile instead.
          if (e.name === 'TypeError') {
            try {
              const response = await fetch(wasmUrl, { priority: 'high' });
              const arrayBuffer = await response.arrayBuffer();
              moduleOrArrayBuffer = await WebAssembly.compile(arrayBuffer);
            } catch (e2) {
              if (retryLimit - 1 == i) {
                monitor.add_monitor(
                  `${useStreaming ? 'CF' : 'FF'}${workerType}`
                );

                globalTracingLogger.error(
                  `Failed to download WASM file using compile: ${wasmUrl} for worker type: ${workerType}`,
                  e2
                );
              }
            }
          } else {
            if (retryLimit - 1 == i)
              globalTracingLogger.error(
                `Failed to download WASM file using ${
                  useStreaming ? 'compileStreaming' : 'fetch'
                }: ${wasmUrl} for worker type: ${workerType}`,
                e
              );
          }
        }

        if (moduleOrArrayBuffer) {
          resolve(moduleOrArrayBuffer);

          if (i > 0) {
            globalTracingLogger.error(
              `after ${i} retry download wasm ${wasmUrl} successed `
            );
          }
          return;
        }
      }

      reject(
        new Error(
          `Unable to download and compile WASM file: ${wasmUrl} for worker type: ${workerType}`
        )
      );
    });

    downloadUrlMapPromise[key] = promise;
    return promise;
  },
  /**
   * @param blob
   * @returns {Promise<String>}
   */
  readBlob: (blob) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.readAsText(blob);
    });
  },
  readBlobAsBuffer: (blob) => {
    return new Promise((resolve, reject) => {
      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        resolve(event.target.result);
      };
      fileReader.readAsArrayBuffer(blob);
    });
  },
  lengthInUtf8Bytes(str) {
    // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
    var m = encodeURIComponent(str).match(/%[89ABab]/g);
    return str.length + (m ? m.length : 0);
  },
  /**
   * @returns {Boolean} true means Little-Endian
   */
  isLittleEndian() {
    let arrayBuffer = new ArrayBuffer(2);
    let uint8Array = new Uint8Array(arrayBuffer);
    let uint16array = new Uint16Array(arrayBuffer);
    uint8Array[0] = 0xaa;
    uint8Array[1] = 0xbb;
    return uint16array[0] === 0xbbaa;
  },
  sleep(millionSeconds) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, millionSeconds);
    });
  },

  isSDKSupportMultiThread: async () => {
    await apiSupportUtility.checkIsSupportMultiThread();
    return apiSupportUtility.getIsSupportMultiThread();
  },
  is32bitChrome: async () => {
    if (navigator.userAgent.indexOf('WOW64') != -1) {
      return true;
    } else if (
      navigator.userAgentData &&
      navigator.userAgentData.getHighEntropyValues
    ) {
      let value = await navigator.userAgentData.getHighEntropyValues(['wow64']);
      return value.wow64;
    } else {
      return false;
    }
  },

  isAMDGraphic() {
    try {
      var renderer_info = this.graphicName;
      var bIsAMD = renderer_info.includes('amd');
      return bIsAMD;
    } catch (e) {
      return false;
    }
  },
  graphicName: globalRenderInfo.renderInfo,
  graphicvendorname: globalRenderInfo.vendor,

  isGraphicShouldUseHardwareAccelerationDecode() {
    // UI check HW decode option or not
    // AMD && isChromeOS() // checked
    // AMD && !isChromeOS() //unchecked
    if (this.isAMDGraphic() && this.isChromeOS()) {
      return true;
    } else if (this.isAMDGraphic() && !this.isChromeOS()) {
      return false;
    }
    return true;
  },

  //Demo : https://jsfiddle.net/b9fgkx80/
  VP9MachineDetect() {
    var mediaConfig = {
      type: 'webrtc',
      video: {
        contentType: 'video/vp9',
        bitrate: 10000000,
        framerate: 25,
        height: 1920,
        width: 1080,
      },
    };
    const decode_promise =
      navigator.mediaCapabilities.decodingInfo(mediaConfig);

    return new Promise((resolve, reject) => {
      decode_promise
        .then((info) => {
          // if we don't even have software support - chrome may not support the codec -
          // throw an error
          if (!info.supported) {
            globalTracingLogger.log(
              "video/vp9 codec isn't supported in software or hardware"
            );
            resolve(false);
          }
          // given the current chrome source, powerEfficient deconstructs into hardware acceleration available
          // https://webrtc.googlesource.com/src/+/refs/heads/main/api/video_codecs/video_decoder_factory.h#49
          resolve(info.powerEfficient);
        })
        .catch((err) => {
          globalTracingLogger.warn(
            'Error when trying to determine support for VP9 codec',
            err
          );
          resolve(false);
        });
    });
  },

  async IsSupportVideoDecodeHardwareAcceleration(decHAOpt = false) {
    if (
      typeof VideoDecoder === 'function' &&
      (!this.browser.isSafari || isSafariVersionHigherThan('17.5'))
    ) {
      var extradata_info = new Uint8Array([
        1, 100, 0, 31, 255, 225, 0, 14, 103, 100, 0, 51, 172, 27, 26, 17, 129,
        64, 22, 201, 160, 16, 7, 0, 5, 104, 200, 66, 60, 48, 0, 5, 104, 82, 16,
        207, 12, 0, 25, 104, 114, 16, 143, 24, 67, 17, 132, 56, 140, 84, 81, 8,
        18, 22, 41, 3, 194, 98, 3, 5, 32, 122, 9, 140, 0, 5, 104, 36, 132, 51,
        203, 0, 5, 104, 46, 132, 51, 195, 0, 25, 104, 54, 132, 35, 198, 16, 196,
        97, 14, 35, 21, 20, 66, 4, 133, 138, 64, 240, 152, 128, 193, 72, 30,
        130, 99, 0, 5, 104, 62, 132, 51, 203,
      ]);

      var config = {
        codec: 'avc1.640028',
        description: extradata_info,
        codedWidth: 1280,
        codedHeight: 720,
        optimizeForLatency: true,
        hardwareAcceleration: decHAOpt ? 'no-preference' : 'prefer-hardware',
      };
      try {
        let result = await VideoDecoder.isConfigSupported(config);
        if (result.supported) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  },
  async IsSupportVideoEncodeHardwareAcceleration() {
    if (
      typeof VideoEncoder === 'function' &&
      (!this.browser.isSafari || isSafariVersionHigherThan('17.5'))
    ) {
      let avcConfig = { format: 'annexb' };
      var config = {
        codec: 'avc1.640028',
        bitrate: 1500000,
        width: 1280,
        height: 720,
        avc: avcConfig,
        framerate: 25,
        hardwareAcceleration: 'no-preference',
        latencyMode: 'realtime',
        bitrateMode: 'constant',
        scalabilityMode: 'L1T2',
      };

      let result = await VideoEncoder.isConfigSupported(config);
      if (result.supported) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },

  AdapterWhiteListCheckForEncoder() {
    if (!IsSupportWebGLOffscreenCanvas()) {
      return -1;
    }
    try {
      var renderer = globalRenderInfo.renderInfo;
      var vendor = globalRenderInfo.vendor;

      var renderer_info = renderer.toLowerCase();

      var bIsARM = vendor.includes('ARM');
      var bIsAMD = renderer_info.includes('amd');

      var bIsIntel = renderer_info.includes('intel');
      var bIsHdGpu = renderer_info.includes('hd graphics');

      var bIsNvidia = renderer_info.includes('nvidia');
      var bIsGeForce = renderer_info.includes('geforce');

      if (bIsIntel) {
        return 0;
      }
      if (bIsARM) {
        return -1;
      }
      if (bIsAMD) {
        return 0;
      }
      if (bIsNvidia) {
        return 0;
      }
      return 0;
    } catch (e) {
      return 0;
    }
  },
  isChromeVersionHigherThan,
  isFirefoxVersionHigherThan,
  isSafariVersionHigherThan,
  async isSDKSupportSIMD() {
    if (typeof WebAssembly !== 'object') return false;
    if (browserType.browser == 'chrome') {
      // for chrome, we only detect simd-support if version >= 84
      if (browserType.version >= 84) {
        await apiSupportUtility.checkIsSupportSIMD();
      } else {
        return false;
      }
    } else {
      await apiSupportUtility.checkIsSupportSIMD();
    }
    return apiSupportUtility.getIsSupportSIMD();
  },
  buffer2stringSplitByComma(buffer) {
    let ints = new Uint8Array(buffer);

    return ints.join(',');
  },
  stringSplitByComma2Buffer(str) {
    try {
      let arr = str.split(',').map((item) => parseInt(item));
      let buffer = new ArrayBuffer(arr.length);
      let ints = new Uint8Array(buffer);

      for (let i = 0; i < arr.length; i++) {
        ints[i] = arr[i];
      }

      return buffer;
    } catch (e) {
      return null;
    }
  },
  removeDuplicates(list, diffCheckFunction) {
    let newList = [];
    list.forEach((item) => {
      let isDup = false;
      for (let i = 0; i < newList.length; i++) {
        if (!diffCheckFunction.call(null, newList[i], item)) {
          isDup = true;
          break;
        }
      }

      if (!isDup) {
        newList.push(item);
      }
    });

    return newList;
  },

  getBrowserVersion() {
    let browserVersion = getBrowserInfo();
    if (browserVersion[0] && browserVersion[0].match(/(\d+)/)) {
      return browserVersion[0].match(/(\d+)/)[0];
    }
    return 'other';
  },
  getIOSMajorVersion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      let v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return parseInt(v[1], 10);
    }
    return null;
  },
  getMacSafariMajorVersion() {
    if (this.browser.isSafari) {
      const v = navigator.userAgent.match(/Version\/([\d\.]+)/);
      return v && v[1] ? parseFloat(v[1]) : null;
    }
    return null;
  },
  isSupportImageCapture() {
    return (
      this.browser?.isChrome &&
      DetectIsSupportImageCapture() &&
      IsSupportWebGLOffscreenCanvas()
    );
  },
  isSupportOffscreenCanvas() {
    return IsSupportWebGLOffscreenCanvas();
  },
  isSupport2dOffscreenCanvas() {
    return typeof OffscreenCanvas === 'function';
  },

  isSupportVideoTrackReader() {
    return DetectisSupportVideoTrackReader() && IsSupportWebGLOffscreenCanvas();
  },
  isSupportMediaStreamTrackProcessor,
  isSupportVideoFrameOrBitmapCapture() {
    return (
      this.isSupportImageCapture() ||
      this.isSupportVideoTrackReader() ||
      this.isSupportMediaStreamTrackProcessor()
    );
  },
  isSupportSharedArrayBuffer() {
    return typeof SharedArrayBuffer !== 'undefined';
  },
  async queryPTZPermisson() {
    try {
      const panTiltZoomPermissionStatus = await navigator.permissions.query({
        name: 'camera',
        panTiltZoom: true,
      });

      if (panTiltZoomPermissionStatus.state == 'granted') {
        return true;
      }

      return false;
    } catch (error) {
      globalTracingLogger.error(
        'Error when querying pan-tilt-zoom permission',
        error
      );
      return false;
    }
  },
  isSupportCameraPan() {
    return !!navigator.mediaDevices?.getSupportedConstraints?.()?.pan;
  },
  isSupportCameraTilt() {
    return !!navigator.mediaDevices?.getSupportedConstraints?.()?.tilt;
  },
  isSupportCameraZoom() {
    return !!navigator.mediaDevices?.getSupportedConstraints?.()?.zoom;
  },
  isSupportPTZ() {
    // if support pan tilt zoom
    const mediaContraints = navigator.mediaDevices?.getSupportedConstraints?.();
    return (
      mediaContraints &&
      mediaContraints.pan &&
      mediaContraints.tilt &&
      mediaContraints.zoom
    );
  },

  get720pcapacity() {
    return HW720PEncoderCapacity.capacityfor720;
  },
  set720pcapacity(ca) {
    HW720PEncoderCapacity.capacityfor720 = ca;
  },
  get1080pcapacity() {
    return HW720PEncoderCapacity.capacity1080;
  },
  set1080pcapacity(ca) {
    HW720PEncoderCapacity.capacity1080 = ca;
  },
  isSupportBrowserWebRTC() {
    return !!(
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia &&
      window.RTCPeerConnection
    );
  },
  getsub1080pcapacity() {
    return HW1080PDeocderCapacity.capacitydecofor1080;
  },
  setsub1080pcapacity(ca) {
    HW1080PDeocderCapacity.capacitydecofor1080 = ca;
  },
  getMaxCountRender: () => {
    if (!isWebRTCMode) {
      const isSupportMultiView =
        IsSupportWebGLOffscreenCanvas() &&
        navigator?.hardwareConcurrency >= 2 &&
        typeof requestAnimationFrame === 'function' &&
        typeof SharedArrayBuffer === 'function';
      if (
        !isSupportMultiView &&
        !jsMediaEngineVariables.enableMultiDecodeVideoWithoutSAB
      ) {
        return 1;
      }
      return 26;
    } else {
      if (DetectAndroidBrowser()) {
        return 16; // some Android devices max decoded number is 16
      } else {
        return 26; // 25 + 1 // ZOOM-861026
      }
    }
  },
  checkAudioAutoPlay() {
    /**
     * Request access to navigator.mediaDevices.getUserMedia,
     *   if user allowed or remembered allowed, then audio can auto play
     *
     * In firefox, if user check remembered allow choice,
     *      the prompt window won't be opened after getUserMedia
     *
     * if user never check remember choice when getUserMedia,
     *      the prompt window will be always opened after getUserMedia.
     *
     *  Firefox has three types of permission controls
     *      1. auto play
     *      2. audio capture
     *      3. video capture
     *
     * In Chrome, if user already allow audio access, (no remember checkbox),
     *      the prompt window won't be opened after getUserMedia.
     *      (But it is not sure, it's based on limited tests)
     *
     * In Edge Chromium, if user already allow audio access, (no remember checkbox),
     *      the prompt window won't be opened after getUserMedia.
     *
     *      Important : Although disable microphone access,audio can auto play. (different from Chrome)
     *
     *
     * Tip : How to reset media-engagement score(in Chrome/Edge)?
     *      Open a guest browser window.
     */
    return new Promise((resolve, reject) => {
      //create a wav audio(16K, 16bits, 0.01s, silence audio)
      let audioArrayBuffer = new ArrayBuffer(684);
      let audioInt32 = new Uint32Array(audioArrayBuffer);
      let header = [
        1179011410, 676, 1163280727, 544501094, 16, 65539, 16000, 64000,
        2097156, 1635017060, 640,
      ];
      audioInt32.set(header, 0);

      let blob = new Blob([audioInt32], { type: 'audio/wav' });
      let blobURL = window.URL.createObjectURL(blob);
      let audio = new Audio(blobURL);

      audio.addEventListener('canplaythrough', () => {
        audio
          .play()
          .then(() => {
            resolve(true);
          })
          .catch((ex) => {
            globalTracingLogger.log('Unable to auto play audio', ex);
            reject(ex);
          })
          .finally(() => {
            window.URL.revokeObjectURL(blobURL);
          });
      });

      /** iphone safari need this to trigger canplaythrouth event */
      if (audio.load) {
        audio.load();
      }
    });
  },
  isSupportSendVideoFullHD() {
    // we support 1080p in videoshare mode. It depends on hardwareconcurrency param as well
    return this.isSupportVideoShareSend() && navigator.hardwareConcurrency >= 8;
  },
  isSupportSendVideoShareFullHD() {
    return false;
  },

  isSupportVideoShare() {
    return true;
  },

  isSupportVideoShareSend() {
    let result = detectBrowser();
    let version;
    if (result.browser == 'chrome') {
      version = this.getBrowserVersion();
    }

    if (!this.isSupportSharedArrayBuffer()) return false;

    if (
      result.browser !== 'chrome' ||
      (version && version <= 100) ||
      navigator.hardwareConcurrency <= 2 ||
      this.isTeslaMode() ||
      (this.isAndroidBrowser() && !this.isMTRAndroidWithSAB()) ||
      this.isIphoneOrIpadBrowser()
    ) {
      return false;
    }
    return true;
  },

  isSupportVideoShareReceive() {
    return true;
  },

  getOption(options, postion, opBits) {
    try {
      let option_length = options.length;

      if (postion > option_length) {
        return 0;
      }
      let option = options.slice(
        option_length - postion - opBits + 1,
        option_length - postion + 1
      );
      if (option) {
        let bits = parseInt(option, 16);
        return bits;
      }
    } catch (e) {}
    return 0;
  },

  async isSupportWebGPURender(mediaBlockConfig) {
    const gpuBlacklist = this.parseGPUBlacklist(mediaBlockConfig);
    const rendererType = await this.evaluateRendererType(
      true,
      false,
      gpuBlacklist
    );
    return rendererType == RenderConst.RENDERER_TYPE.WEBGPU;
  },

  getMachineCapability() {
    machineInfo.VE =
      jsMediaEngineVariables?.localVideoPara?.VE?.buffer.byteLength;
    machineInfo.VD =
      jsMediaEngineVariables?.localVideoPara?.VD?.buffer.byteLength;
    machineInfo.SE =
      jsMediaEngineVariables?.localVideoPara?.SE?.buffer.byteLength;
    machineInfo.SD =
      jsMediaEngineVariables?.localVideoPara?.SD?.buffer.byteLength;
    machineInfo.dt =
      jsMediaEngineVariables?.localVideoPara?.videodecodethreadnumb;
    machineInfo.et =
      jsMediaEngineVariables?.localVideoPara?.videoencodethreadnumb;
    machineInfo.MT =
      jsMediaEngineVariables?.localVideoPara?.isSupportMultiThread;
    machineInfo.time = performance.now();
    return machineInfo;
  },
  /**
   * @description provided to ui to call this fro getting same
   */
  watermark: (() => {
    const WaterMarkRGBA = new JsMediaSDK_WaterMarkRGBA();
    const waterMarkCanvas = document.createElement('canvas');
    const watermarkInfo = {
      enableWaterMark: false,
      waterMarkText: '',
      watermarkOpacity: 0,
      watermarkRepeated: false,
      watermarkPosition: undefined,
    };
    const getHigherQualitySize = function (width, height) {
      if (width < 640 && width) {
        const zoom = 640 / width;
        width = 640;
        height = Math.round(height * zoom);
      }
      return { width, height };
    };
    return {
      getWaterMarkData({ width, height }) {
        if (!watermarkInfo.enableWaterMark) return null;
        /** follow native client, if video size too small, always render watermark in middle */
        const position =
          width < 512 || height < 288 ? 16 : watermarkInfo.watermarkPosition;
        const shouldRepeated =
          watermarkInfo.watermarkRepeated && width > 306 && height > 202;
        const highQualitySize = getHigherQualitySize(width, height);
        width = highQualitySize.width;
        height = highQualitySize.height;

        const watermarkData = shouldRepeated
          ? WaterMarkRGBA.Get_Repeated_WaterMarkRGBA({
              canvas: waterMarkCanvas,
              name: watermarkInfo.waterMarkText,
              width: highQualitySize.width,
              height: highQualitySize.height,
              opacity: watermarkInfo.watermarkOpacity,
              position,
              convertToDataUrl: true,
            })
          : WaterMarkRGBA.Get_WaterMarkRGBA({
              canvas: waterMarkCanvas,
              name: watermarkInfo.waterMarkText,
              width: highQualitySize.width,
              height: highQualitySize.height,
              opacity: watermarkInfo.watermarkOpacity,
              position,
              convertToDataUrl: true,
            });
        return watermarkData;
      },
      updateWaterMarkInfo(params) {
        Object.assign(watermarkInfo, params);
      },
    };
  })(),

  isSupportAudioDenoise(isWebRTC = false) {
    // TODO: denoise need support simd
    if (typeof AudioContext !== 'function') return false;
    let result = detectBrowser();
    if (result.browser == 'chrome') {
      let version = this.getBrowserVersion();
      if (version <= 100) return false;
    }
    const supportSIMD = WebAssembly.validate(
      new Uint8Array([
        0, 97, 115, 109, 1, 0, 0, 0, 1, 4, 1, 96, 0, 0, 3, 2, 1, 0, 10, 9, 1, 7,
        0, 65, 0, 253, 15, 26, 11,
      ])
    );

    /*
    https://github.com/GoogleChromeLabs/wasm-feature-detect#contributing
    WebAssembly.validate is sync now. But it's possible to be async in the future
    */
    if (supportSIMD === false) {
      return false;
    } else if (supportSIMD !== true) {
      globalTracingLogger.error("WebAssembly.validate don't return a boolean");
      return false;
    }

    if (!isWebRTC && !this.isSupportSharedArrayBuffer()) return false;

    if (
      navigator.hardwareConcurrency <= 2 ||
      this.isTeslaMode() ||
      (this.isAndroidBrowser() && !this.isMTRAndroidWithSAB()) ||
      this.isIphoneOrIpadBrowser()
    ) {
      return false;
    }
    return true;
  },

  //stereo can't use peerConnection do AEC
  isSupportPlayStereo() {
    return this.browser.isFirefox ||
      this.browser.isSafari ||
      this.isSupportChromeWideAEC()
      ? true
      : false;
  },

  //if earphones has only one channel, the right channel will get 0.
  //receiver can only hear from their left earphone.
  isBrowserSupportStereo() {
    if (this.browser.isSafari) return false;
    else return true;
  },

  isSupportShare2ndAudioDevice(isWebRTC) {
    //safari not supported capture two stream from different devices at the same time
    if (this.browser.isSafari || this.isIphoneOrIpadBrowser()) return false;
    if (isWebRTC) return true;
    //on mac chrome, close 3A and use audioContext will has noise, chrome fixed this issue on the latest version
    if (this.isMac() && !isChromeVersionHigherThan(126)) return false;
    //wasm solution share audio need SAB
    if (!this.isSupportSharedArrayBuffer()) return false;
    return true;
  },

  //mac chrome will get noise if join computer audio when share stereo audio
  //return false now
  isSupportSharingStereo() {
    return false;
  },

  isSupportVideoActiveChannel() {
    return true;
  },

  async evaluateRendererType(
    isWebGPUFeatureEnabled = false,
    isWebGL2FeatureEnabled = false,
    gpuBlacklist = null
  ) {
    let rendererType = RenderConst.RENDERER_TYPE.WEBGL;
    let canvas = null;
    const isOffscreenCanvasSupported = IsSupportWebGLOffscreenCanvas();

    try {
      if (this.isWebGL2Supported(isWebGL2FeatureEnabled)) {
        rendererType = RenderConst.RENDERER_TYPE.WEBGL_2;
      } else {
        globalTracingLogger.log(
          `evaluateRendererType() WebGL 2 is not supported.`
        );
      }

      if (!this.isSupportVideoFrame()) {
        globalTracingLogger.log(
          `evaluateRendererType() WebGPU is not supported. (reason: VideoFrame is not supported)`
        );

        return rendererType;
      }

      const isWebGPUAllowedOnTargetPlatforms =
        this.isMac() || this.isWindows() || this.isChromeOS() || this.isLinux();
      if (!isWebGPUAllowedOnTargetPlatforms) {
        globalTracingLogger.log(
          `evaluateRendererType() WebGPU is not supported. (reason: unsupported platform)`
        );

        return rendererType;
      }

      // WebGPU is supported on the browsers:
      // 1. Chrome/Chrome Canary/Chromium/Edge >= 119
      // 2. Opera(chromium based) >= 108
      // 2. Android Chrome/Edge >= 121
      const isWebGPUAllowedOnTargetBrowsers =
        (this.browser.isChrome && this.isChromeVersionHigherThan(119)) ||
        (this.isOpera() && this.isOperaVersionHigherThan(108)) ||
        (this.browser.isChrome &&
          (this.isLinux() || this.isAndroidBrowser()) &&
          this.isChromeVersionHigherThan(121));

      if (!isWebGPUAllowedOnTargetBrowsers) {
        globalTracingLogger.log(
          `evaluateRendererType() WebGPU is not supported. (reason: unmatched browser)`
        );
        return rendererType;
      }

      if (!navigator.gpu) {
        return rendererType;
      }

      const adapter = await navigator.gpu.requestAdapter();
      if (!adapter) {
        globalTracingLogger.log(
          `evaluateRendererType() WebGPU is not supported. (reason: no available adapter.)`
        );
        return rendererType;
      }

      const gpuDevice = await adapter.requestDevice();
      if (!gpuDevice) {
        globalTracingLogger.log(
          `evaluateRendererType() WebGPU is not supported. (reason: no available device.)`
        );

        return rendererType;
      }

      let adapterInfo = undefined;
      if (typeof adapter.requestAdapterInfo === 'function') {
        adapterInfo = await adapter.requestAdapterInfo();
      } else {
        if ('info' in adapter) {
          adapterInfo = adapter.info;
        }
      }

      if (!adapterInfo) {
        globalTracingLogger.log(
          `evaluateRendererType() WebGPU is not supported. (reason: no available adapter info.)`
        );

        return rendererType;
      }

      globalTracingLogger.log(
        `evaluateRendererType() GPUAdapterInfo: arch=${adapterInfo.architecture}, vendor=${adapterInfo.vendor}`
      );

      console.log(
        `evaluateRendererType() GPUAdapterInfo: arch=${adapterInfo.architecture}, vendor=${adapterInfo.vendor}`
      );

      const gpuProfile = this.queryDeviceProfile(adapterInfo);
      const onWhitelist = this.isGPUProfileOnWebGPUWhitelist(
        gpuProfile,
        gpuBlacklist
      );
      if (!onWhitelist) {
        return rendererType;
      }

      if (isOffscreenCanvasSupported) {
        canvas = new OffscreenCanvas(1, 1);
        const gpuContext = canvas.getContext('webgpu');
        if (!gpuContext) {
          globalTracingLogger.log(
            `evaluateRendererType() WebGPU is not supported. (reason: no available GPUContext.)`
          );
          return rendererType;
        }

        if (!this.isOpFeatureEnabled(isWebGPUFeatureEnabled)) {
          globalTracingLogger.log(
            `evaluateRendererType() WebGPU is not supported. (reason: WebGPU is not allowed for this account.)`
          );
          return rendererType;
        }

        canvas = null;
        rendererType = RenderConst.RENDERER_TYPE.WEBGPU;
      } else {
        globalTracingLogger.log(
          `evaluateRendererType() OffscreenCanvas is not supported while checking WebGPU.`
        );
      }

      return rendererType;
    } catch (error) {
      globalTracingLogger.error('evaluateRendererType()', error);
      return rendererType;
    } finally {
      canvas = null;
    }
  },

  isOpFeatureEnabled(option) {
    return option & 0b1;
  },

  queryDeviceProfile(adapterInfo) {
    if (adapterInfo) {
      let simpleInfo = {};
      simpleInfo.architecture = adapterInfo.architecture;
      simpleInfo.vendor = adapterInfo.vendor;
      return simpleInfo;
    }

    return null;
  },

  isGPUProfileOnWebGPUWhitelist(gpuProfile, gpuBlacklist) {
    if (!gpuProfile) {
      return false;
    }

    const vendor = gpuProfile.vendor;
    const arch = gpuProfile.architecture;
    const index = RenderConst.GPU_VENDOR_WHITELIST.indexOf(vendor);
    if (index == -1) {
      // if -1, means the vendor is not on the whitelist, that is, not allowed by media-sdk
      // so, no need to check the gpuBlacklist here, return false directly
      globalTracingLogger.log(
        `isGPUProfileOnWebGPUWhitelist() vendor:${vendor} arch:${arch} is not on the vendor whitelist!`
      );
      return false;
    } else {
      if (gpuBlacklist !== null) {
        if (this.isHitGPUBlacklist(vendor, arch, gpuBlacklist)) {
          return false;
        }
      }
      return true;
    }
  },

  isHitGPUBlacklist(targetVendor, targetArch, gpuBlacklist) {
    if (
      targetVendor === '' ||
      targetVendor === undefined ||
      targetArch === undefined ||
      gpuBlacklist === undefined
    ) {
      return false;
    }

    for (let i = 0; i < gpuBlacklist.length; i++) {
      const entry = gpuBlacklist[i];
      if (
        targetVendor.includes(entry.vendor) &&
        targetArch.includes(entry.generations)
      ) {
        return true;
      }
    }
    return false;
  },

  parseGPUBlacklist(mediaBlockConfig) {
    let gpuBlacklist = null;
    if (mediaBlockConfig) {
      try {
        const mediaBlockConfigObj = JSON.parse(mediaBlockConfig);
        gpuBlacklist = mediaBlockConfigObj?.GPUBlacklist;
      } catch {
        globalTracingLogger.error(
          `parseGPUBlacklist() failed to read mediaBlockConfig! val=${mediaBlockConfig}`
        );
      }
    }
    return gpuBlacklist;
  },

  /**
   * Evaluate a list of supported renderer types.
   *
   * @param {string} mediaFeatureOptions all feature options for MediaSDK
   * @param {string} mediaBlockConfig a json which configures like GPU blacklist, etc
   * @returns a list of renderer types supported by MediaSDK
   */
  async getRendererTypeList(mediaFeatureOptions, mediaBlockConfig) {
    const gpuBlacklist = this.parseGPUBlacklist(mediaBlockConfig);

    const bitBundle = [
      {
        bitIndex: BIT_MAPPING.WEBGPU_RENDERER.index,
        readCount: 1,
        defaultVal: BIT_MAPPING.WEBGPU_RENDERER.default,
      },
      {
        bitIndex: BIT_MAPPING.WEBGL2_RENDERER.index,
        readCount: 1,
        defaultVal: BIT_MAPPING.WEBGL2_RENDERER.default,
      },
    ];

    const bitMapping = ABOptionsReader.batchRead(
      mediaFeatureOptions,
      bitBundle
    );

    const isWebGPURendererFeatureEnabled = !!bitMapping.get(
      BIT_MAPPING.WEBGPU_RENDERER.index
    );

    const isWebGL2RendererFeatureEnabled = !!bitMapping.get(
      BIT_MAPPING.WEBGL2_RENDERER.index
    );

    const isWebGPUFeatureSupported = await this.isWebGPUSupported(
      isWebGPURendererFeatureEnabled,
      gpuBlacklist
    );
    const isWebGL2FeatureSupported = this.isWebGL2Supported(
      isWebGL2RendererFeatureEnabled
    );
    const isWebGLFeatureSupported = IsSupportWebGLOffscreenCanvas();

    const rendererTypeList = [];
    if (isWebGPUFeatureSupported) {
      rendererTypeList.push({
        rendererType: RenderConst.RENDERER_TYPE.WEBGPU,
        rendererName: 'WebGPU',
      });
    }

    if (isWebGL2FeatureSupported) {
      rendererTypeList.push({
        rendererType: RenderConst.RENDERER_TYPE.WEBGL_2,
        rendererName: 'WebGL2',
      });
    }

    if (isWebGLFeatureSupported) {
      rendererTypeList.push({
        rendererType: RenderConst.RENDERER_TYPE.WEBGL,
        rendererName: 'WebGL',
      });
    }

    return rendererTypeList;
  },

  /**
   * Evaluates the WebRTC strategy based on selection, media options, and device compatibility.
   *
   * This function determines whether to enable the WebRTC feature for a given media feature option
   * set, based on several conditions:
   * 1. Checks if the WebRTC feature is enabled in the media feature options.
   * 2. Validates the passed WebRTC selection to determine if it's within the allowed range.
   * 3. If the selection is set to disable WebRTC, immediately returns a result indicating WebRTC
   *    should not be used.
   * 4. Checks if the device is on a whitelist for WebRTC usage, and if so, further evaluates
   *    compatibility with a blacklist and other criteria.
   * 5. Determines if WebRTC should be used based on browser and device support details.
   *
   * @param {number} webrtcSelection - The selected WebRTC strategy to apply, such as ENABLED, DISABLED, or AUTO.
   * @param {string} mediaFeatureOptions - A string containing various media-related feature options.
   * @param {Array|null} [webrtcBlacklist=null] - An optional list specifying devices or browsers where WebRTC is not supported.
   * @returns {Object} - An object containing:
   *   - `shouldUseWebRTC` (boolean): Indicates if WebRTC should be used.
   *   - `errno` (number): An error code indicating the evaluation result or reason WebRTC cannot be used.
   */
  evaluateWebRTCStrategy(
    webrtcSelection,
    mediaFeatureOptions,
    webrtcBlacklist = null
  ) {
    // first, correct webrtcSelection to avoid VSDK random input
    if (
      webrtcSelection !== RenderConst.WEBRTC_STG.DISABLED &&
      webrtcSelection !== RenderConst.WEBRTC_STG.ENABLED &&
      webrtcSelection !== RenderConst.WEBRTC_STG.AUTO
    ) {
      globalTracingLogger.error(
        `evaluateWebRTCStrategy() invalid webrtcSelection(${webrtcSelection}) from caller.`
      );
      webrtcSelection = RenderConst.WEBRTC_STG.AUTO;
    }

    let _webrtcStrategy = {
      shouldUseWebRTC: false,
      errNo: RenderConst.WEBRTC_STG_ERRNO.UNKNOWN,
      errMsg: '',
    };

    const isWebRTCFeatureEnabled =
      this.isWebRTCFeatureEnabled(mediaFeatureOptions);
    if (!isWebRTCFeatureEnabled) {
      _webrtcStrategy.shouldUseWebRTC = false;
      _webrtcStrategy.errNo =
        RenderConst.WEBRTC_STG_ERRNO.FEATURE_OPTION_DISABLED;
      _webrtcStrategy.errMsg =
        'webrtc is disabled(feature option is disabled).';
      return _webrtcStrategy;
    }

    const osInfo = this.getOSInfo();
    let browserName = osInfo?.browser?.name || '';
    let browserVersion = osInfo?.browser?.version || '';
    let renderInfo = globalRenderInfo;
    const deviceInfo = {
      os: osInfo?.os,
      browserName: browserName,
      browserVersion: browserVersion,
      vendor: renderInfo.vendor,
      renderInfo: renderInfo.renderInfo,
      isAstcSupported: renderInfo.isAstcSupported,
    };

    // if caller tells me to disable WebRTC solution, that is, WebRTC will not be used by caller
    // we don't need to do more things, just to return a result to caller
    if (webrtcSelection === RenderConst.WEBRTC_STG.DISABLED) {
      globalTracingLogger.log(
        `evaluateWebRTCStrategy() WebRTC is disabled by caller.`
      );
      _webrtcStrategy.shouldUseWebRTC = false;
      _webrtcStrategy.errNo = RenderConst.WEBRTC_STG_ERRNO.SUCCEED;
      _webrtcStrategy.errMsg = 'webrtc is disabled(disabled by caller).';
      return _webrtcStrategy;
    }

    const isBrowserSupportWebRTC = this.isSupportBrowserWebRTC();
    try {
      // check whitelist first, if on whitelist, check the blacklist next
      const isDeviceOnWhitelist =
        MediaConfigParser.isOnWebRTCWhitelist(deviceInfo);

      if (isDeviceOnWhitelist) {
        const result = MediaConfigParser.evalWebRTCStrategy(
          webrtcBlacklist,
          deviceInfo,
          isBrowserSupportWebRTC,
          webrtcSelection
        );

        // update error no first
        _webrtcStrategy.errNo = result.errNo;
        _webrtcStrategy.errMsg = result.errMsg;

        // decide how to use WebRTC solution by result
        if (result.stg == RenderConst.WEBRTC_STG.ENABLED) {
          _webrtcStrategy.shouldUseWebRTC = true;
        } else if (result.stg == RenderConst.WEBRTC_STG.DISABLED) {
          _webrtcStrategy.shouldUseWebRTC = false;
        } else if (result.stg == RenderConst.WEBRTC_STG.AUTO) {
          _webrtcStrategy.shouldUseWebRTC = this.isDefaultToUseWebRTC(
            mediaFeatureOptions,
            deviceInfo
          );
        } else {
          _webrtcStrategy.shouldUseWebRTC = false;
          _webrtcStrategy.errNo =
            RenderConst.WEBRTC_STG_ERRNO.UNKNOWN_SELECTION;
          _webrtcStrategy.errMsg =
            'webrtc is disabled(unknown selection type).';
          globalTracingLogger.error(
            `evaluateWebRTCStrategy() unknown WEBRTC_STG:${result.stg}`
          );
        }
      } else {
        // if device is not on the whitelist of webrtc, it should not allow to use
        _webrtcStrategy.shouldUseWebRTC = false;
        _webrtcStrategy.errNo =
          RenderConst.WEBRTC_STG_ERRNO.DEVICE_NOT_ON_WHITELIST;
        _webrtcStrategy.errMsg = 'webrtc is disabled(not on the whitelist).';
      }
    } catch (e) {
      // if meet some unexpected ex, here is still a chance to make a decision: use WebRTC solution or not
      globalTracingLogger.error('evaluateWebRTCStrategy() error:', e);
      _webrtcStrategy.shouldUseWebRTC = false;
      _webrtcStrategy.errNo = RenderConst.WEBRTC_STG_ERRNO.OTHER_EX;
      _webrtcStrategy.errMsg = `webrtc is disabled(unexpected error:${e.errorMessage}).`;
    }

    globalTracingLogger.directReport(
      `evaluateWebRTCStrategy() stg=${JSON.stringify(
        _webrtcStrategy
      )}, isBrowserSupportWebRTC=${isBrowserSupportWebRTC}, webrtcBlacklist=${JSON.stringify(
        webrtcBlacklist
      )}, selection=${webrtcSelection}`
    );

    return _webrtcStrategy;
  },

  /**
   * Checks if the WebRTC feature is enabled in the provided media feature options.
   * If bit.49 is not configured, WebRTC solution will not be used at all.
   *
   * This function reads the WebRTC feature enablement flag from the media feature options
   * using the ABOptionsReader. It reads the bit specified by BIT_MAPPING for the
   * ENABLE_WEBRTC_FEATURE and logs the value for tracing purposes.
   *
   * @param {string} mediaFeatureOptions - The options object that contains various media feature configurations.
   * @returns {boolean} - The value indicating if the WebRTC feature is enabled (1 if enabled, 0 if disabled).
   */
  isWebRTCFeatureEnabled(mediaFeatureOptions) {
    const optionVal = ABOptionsReader.read(
      mediaFeatureOptions,
      BIT_MAPPING.ENABLE_WEBRTC_FEATURE.index,
      1,
      BIT_MAPPING.ENABLE_WEBRTC_FEATURE.default
    );
    globalTracingLogger.log(`isWebRTCFeatureEnabled() optionVal:${optionVal}`);
    return !!optionVal;
  },

  /**
   * Retrieves and constructs the WebRTC strategy options from media feature settings.
   *
   * This function reads the WebRTC strategy option value from the provided media feature options,
   * logs it for tracing, and then builds a list of available WebRTC strategy options. Each option
   * indicates if it’s the default selection based on the current media feature settings.
   *
   * @param {string} mediaFeatureOptions - The options string that contains various media feature configurations.
   * @returns {number} - The current WebRTC strategy option value.
   */
  getWebRTCStrategyOptions(mediaFeatureOptions) {
    const optionVal = ABOptionsReader.read(
      mediaFeatureOptions,
      BIT_MAPPING.WEBRTC_STG.index,
      1,
      BIT_MAPPING.WEBRTC_STG.default
    );
    globalTracingLogger.log(`getWebRTCStrategyOption() optionVal:${optionVal}`);

    const strategyOptions = [];
    Object.entries(RenderConst.WEBRTC_STG).forEach(([key, value]) => {
      const _option = {
        key: key,
        value: value,
        isDefault: false,
      };

      if (optionVal === value) {
        _option.isDefault = true;
      }

      strategyOptions.push(_option);
    });

    return strategyOptions;
  },

  isDefaultToUseWebRTC(mediaFeatureOptions, deviceInfo) {
    // it's flexible to decide whether use WebRTC solution or not
    // like if render info is SwiftShader, it's better to use WebRTC
    // Prepare device OS in lowercase for easier comparison.
    const optionForceToUseWebRTC = ABOptionsReader.read(
      mediaFeatureOptions,
      BIT_MAPPING.FORCE_TO_USE_WEBRTC.index,
      1,
      BIT_MAPPING.FORCE_TO_USE_WEBRTC.default
    );

    if (
      optionForceToUseWebRTC &
      BIT_MAPPING.FORCE_TO_USE_WEBRTC.candidates.VIDEO_ON_BROWSER_32BIT
    ) {
      if (check32bitChrome) {
        return true;
      }
    }

    const _deviceInfo = {
      os: deviceInfo.os?.toLowerCase(),
    };

    // Read the WebRTC configuration option.
    const optionVal = ABOptionsReader.read(
      mediaFeatureOptions,
      BIT_MAPPING.WEBRTC_AUTO_CONFIG.index,
      1,
      BIT_MAPPING.WEBRTC_AUTO_CONFIG.default
    );

    // If no config is available or bit is not set, WebRTC is not used by default.
    if (optionVal === BIT_MAPPING.WEBRTC_AUTO_CONFIG.candidates.NO_CONFIG) {
      return false;
    }

    // force WebRTC if SharedArrayBuffer is not support
    if (optionVal === BIT_MAPPING.WEBRTC_AUTO_CONFIG.candidates.SAB_OFF) {
      if (!this.isSupportSharedArrayBuffer()) {
        console.log(`force webc when sab off`);
        return true;
      }
    }

    // Define mapping for operating system to WebRTC configuration flags.
    const osFlags = [
      {
        os: 'android',
        flag: BIT_MAPPING.WEBRTC_AUTO_CONFIG.candidates.MOB_ANDROID,
      },
      { os: 'ios', flag: BIT_MAPPING.WEBRTC_AUTO_CONFIG.candidates.MOB_IOS },
      { os: 'win', flag: BIT_MAPPING.WEBRTC_AUTO_CONFIG.candidates.DESKTOP },
      { os: 'mac', flag: BIT_MAPPING.WEBRTC_AUTO_CONFIG.candidates.DESKTOP },
      {
        os: 'chromium os',
        flag: BIT_MAPPING.WEBRTC_AUTO_CONFIG.candidates.DESKTOP,
      },
    ];

    // Check if any configured flag matches the device's OS.
    return osFlags.some(
      ({ os, flag }) => optionVal & flag && _deviceInfo.os.includes(os)
    );
  },

  isWebGL2Supported(isWebGL2FeatureEnabled) {
    if (typeof OffscreenCanvas !== 'function') {
      return false;
    }

    return (
      isWebGL2FeatureEnabled &&
      LocalIsSupportWebGLOffscreenCanvas.isSupportWebgl2
    );
  },

  isWebGL2SupportedWhenOpEnabled() {
    return LocalIsSupportWebGLOffscreenCanvas.isSupportWebgl2;
  },

  async isWebGPUSupported(isWebGPUFeatureEnabled, gpuBlacklist) {
    if (!isWebGPUFeatureEnabled) {
      return false;
    }

    if (!this.isSupportVideoFrame()) {
      return false;
    }

    const isWebGPUAllowedOnTargetPlatforms =
      this.isMac() || this.isWindows() || this.isChromeOS() || this.isLinux();
    if (!isWebGPUAllowedOnTargetPlatforms) {
      return false;
    }

    // WebGPU is supported on the browsers:
    // 1. Chrome/Chrome Canary/Chromium/Edge >= 119
    // 2. Opera(chromium based) >= 108
    // 2. Android Chrome/Edge >= 121
    const isWebGPUAllowedOnTargetBrowsers =
      (this.browser.isChrome && this.isChromeVersionHigherThan(119)) ||
      (this.isOpera() && this.isOperaVersionHigherThan(108)) ||
      (this.browser.isChrome &&
        (this.isLinux() || this.isAndroidBrowser()) &&
        this.isChromeVersionHigherThan(121));

    if (!isWebGPUAllowedOnTargetBrowsers) {
      return false;
    }

    if (!navigator.gpu) {
      return false;
    }

    const adapter = await navigator.gpu.requestAdapter();
    if (!adapter) {
      return false;
    }

    const gpuDevice = await adapter.requestDevice();
    if (!gpuDevice) {
      return false;
    }

    let adapterInfo = undefined;
    if (typeof adapter.requestAdapterInfo === 'function') {
      adapterInfo = await adapter.requestAdapterInfo();
    } else {
      if ('info' in adapter) {
        adapterInfo = adapter.info;
      }
    }

    if (!adapterInfo) {
      return false;
    }

    const gpuProfile = this.queryDeviceProfile(adapterInfo);
    const onWhitelist = this.isGPUProfileOnWebGPUWhitelist(
      gpuProfile,
      gpuBlacklist
    );
    if (!onWhitelist) {
      globalTracingLogger.log(
        `isWebGPUSupported() hit blacklist! profile=${JSON.stringify(
          gpuProfile
        )}, blacklist=${JSON.stringify(gpuBlacklist)}`
      );
      return false;
    }

    let canvas = new OffscreenCanvas(1, 1);
    const gpuContext = canvas.getContext('webgpu');
    if (!gpuContext) {
      canvas = null;
      return false;
    }

    canvas = null;
    return true;
  },

  /**
   * Check whether the input renderer type is supported.
   * @param {*} rendererType the renderer type is supported or not
   * @param {*} isWebGPUFeatureEnabled value of WebGPU feature from OP
   * @param {*} isWebGL2FeatureEnabled value of WebGL_2 feature from OP
   * @param {*} gpuBlacklist a blacklist of blocked GPUs
   * @returns a boolean indicates that whether the renderer type is supported
   */
  async isRendererTypeSupported(
    rendererType,
    isWebGPUFeatureEnabled,
    isWebGL2FeatureEnabled,
    gpuBlacklist
  ) {
    if (
      rendererType <= RenderConst.RENDERER_TYPE.UNDEFINED ||
      rendererType > RenderConst.RENDERER_TYPE.WEBGL_2
    ) {
      return false;
    }

    // WebGL is supported as the default renderer type
    if (rendererType === RenderConst.RENDERER_TYPE.WEBGL) {
      return IsSupportWebGLOffscreenCanvas();
    } else if (rendererType === RenderConst.RENDERER_TYPE.WEBGL_2) {
      return this.isWebGL2Supported(isWebGL2FeatureEnabled);
    } else if (rendererType === RenderConst.RENDERER_TYPE.WEBGPU) {
      return await this.isWebGPUSupported(isWebGPUFeatureEnabled, gpuBlacklist);
    } else {
      return false;
    }
  },

  videoToMediaStreamManager: (() => {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    let _video;
    let _width = 640;
    let _height = 360;
    let _stream;
    const frameRate =
      navigator.hardwareConcurrency && navigator.hardwareConcurrency < 4
        ? 10
        : 24;
    /** chrome capture stream framerate will be low if canvas don't draw new frame */
    const maxFrameDuration =
      1000 / (browserType.browser === 'chrome' ? frameRate : 10);
    /** chrome timeupdate too low, need to manual draw faster */
    let frameRateControlTimer = null;
    let lastTimeStamp = 0;
    let updateFnHanlder = null;
    return {
      _draw() {
        if (!_video) return;
        this.drawCanvas(canvas, ctx, _width, _height);
        const now = performance.now();
        if (lastTimeStamp) {
          const duration = now - lastTimeStamp;
          if (duration > maxFrameDuration) {
            this._setFasterTimer();
          } else if (duration < maxFrameDuration * 0.8) {
            this._clearFastTimer();
          }
        }
        lastTimeStamp = now;
      },
      _setFasterTimer() {
        if (frameRateControlTimer) return;
        frameRateControlTimer = setInterval(() => {
          if (
            _video &&
            _video.currentTime > 0 &&
            !_video.paused &&
            !_video.ended &&
            performance.now() - lastTimeStamp >= maxFrameDuration / 2
          ) {
            this.drawCanvas(canvas, ctx, _width, _height);
          }
        }, maxFrameDuration);
      },
      _clearFastTimer() {
        lastTimeStamp = 0;
        clearInterval(frameRateControlTimer);
        frameRateControlTimer = null;
      },
      drawCanvas(canvas, ctx, width, height) {
        canvas.width = width;
        canvas.height = height;
        let displayWidth = 0;
        let displayHeight = 0;
        if (_video.videoWidth / _video.videoHeight > width / height) {
          displayHeight = _video.videoHeight;
          displayWidth = (width / height) * displayHeight;
        } else {
          displayWidth = _video.videoWidth;
          displayHeight = (height / width) * displayWidth;
        }
        ctx.drawImage(
          _video,
          (_video.videoWidth - displayWidth) / 2,
          (_video.videoHeight - displayHeight) / 2,
          displayWidth,
          displayHeight,
          0,
          0,
          width,
          height
        );
      },
      startCapture(video, width, height) {
        _video = video;
        if (width && height && width / height === 16 / 9) {
          _width = width;
          _height = height;
        }
        if (updateFnHanlder) {
          _video.removeEventListener('timeupdate', updateFnHanlder);
        }
        updateFnHanlder = this._draw.bind(this);
        video.addEventListener('timeupdate', updateFnHanlder);
        _stream = canvas.captureStream(frameRate);
        return _stream;
      },
      stopCapture() {
        if (updateFnHanlder) {
          _video.removeEventListener('timeupdate', updateFnHanlder);
        }
        this._clearFastTimer();
        if (_stream) {
          _stream.getVideoTracks().forEach((track) => track.stop());
          _stream = null;
        }
        _video = null;
      },
      isSupported() {
        return typeof HTMLCanvasElement.prototype.captureStream === 'function';
      },
    };
  })(),
  audioToMediaStreamMananger: (() => {
    let _audio = null;
    let _audioContext = null;
    let _stream = null;
    /** chrome has bug: https://bugs.chromium.org/p/chromium/issues/detail?id=851310
     * when audio tag connect to a audioContext, the binding relationship can't be destroy,
     * so we should cache audio tag with audioContext
     */
    let _elementSource = null;
    let _previousAudio = null;
    return {
      startCapture(audio) {
        if (!_audioContext) {
          _audioContext = createAudioContext('File');
        }
        if (_previousAudio && audio === _previousAudio) {
          _audio = _previousAudio;
        } else {
          this.destroy();
          _audio = audio;
          _previousAudio = _audio;
          _elementSource = _audioContext.createMediaElementSource(_audio);
          const destination = _audioContext.createMediaStreamDestination();
          _elementSource.connect(destination);
          _stream = destination.stream;
        }
        if (
          _audioContext.state === 'suspended' ||
          _audioContext.state === 'interrupted'
        ) {
          _audioContext.resume().catch((e) => {
            globalTracingLogger.error('File audio context resume error: ', e);
          });
        }
        return _stream;
      },
      stopCapture() {
        if (_audioContext) {
          _audioContext.suspend();
        }
        _audio = null;
      },
      /** destroy ref, otherwise gc can't clear audio tag ref */
      destroy(keepAudioContext = true) {
        if (_elementSource) {
          _elementSource.disconnect();
        }
        if (_stream) {
          _stream.getAudioTracks().forEach((track) => track.stop());
          _stream = null;
        }
        _previousAudio = null;
        _elementSource = null;
        if (!keepAudioContext && _audioContext) {
          _audioContext.close();
          _audioContext = null;
        }
      },
      isAudioFileStream(stream) {
        return stream === _stream;
      },
      isSupported() {
        return (
          typeof AudioContext !== 'undefined' &&
          typeof AudioContext.prototype.createMediaStreamDestination ===
            'function' &&
          typeof AudioContext.prototype.createMediaElementSource === 'function'
        );
      },
    };
  })(),

  /* enforceSelect:
   *                undefined --- selected by OP and other features logic---0
   *                0 --- enforce disable audio bridge---1
   *                1 --- enforce enable audio bridge---2
   *
   */
  evaluateAudioStrategy(options, enforceSelect = undefined) {
    if (this.isEnforceWasmMachine()) return false;
    const opResult = ABOptionsReader.read(options, 1, 2);
    const isAppleDevices =
      /iPad|iPhone|iPod/i.test(navigator.userAgent) ||
      (/Macintosh/i.test(navigator.userAgent) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2);
    const isAndroidDevices = navigator.userAgent.match(/Android/i);
    const isOpenHarmony = navigator.userAgent.match(
      /\(([^;]+); OpenHarmony ([\d.]+)\)/
    );
    const isMTRDevices = this.isMTRAndroid() || this.isMTRWindows();
    const isTeslaModeDevices = this.isTeslaMode();
    const ignoreProbResult = (1 << 7) & opResult;

    if (isMTRDevices) {
      return (1 << 3) & opResult;
    } else if (isTeslaModeDevices) {
      return (1 << 1) & opResult;
    } else if (isAndroidDevices || isOpenHarmony) {
      return (1 << 2) & opResult;
    } else if (isAppleDevices) {
      return (1 << 0) & opResult;
    } else if (ignoreProbResult || audioProbResult) {
      return this.isDefaultAudioBridgeMachine(options, opResult, enforceSelect);
    }
    return false;
  },

  isEnforceWasmMachine() {
    //if not support RTCPeerConnection, use wasm
    if (typeof RTCPeerConnection === 'undefined') return true;
    let info = this.getOSInfo();
    //if Firefox on desktop, use wasm， otherwise use webrtc
    if (isMobileDevice()) return false;
    return (
      info?.browser?.name?.toLowerCase().includes('firefox') ||
      this.browser.isFirefox
    );
  },

  isDefaultAudioBridgeMachine(options, opResult, enforceSelect = undefined) {
    if (enforceSelect === ENABLE_AUDIOBRIDGE) return true;
    if (enforceSelect === DISABLE_AUDIOBRIDGE) return false;

    const optionForceToUseWebRTC = ABOptionsReader.read(
      options,
      BIT_MAPPING.FORCE_TO_USE_WEBRTC.index,
      1,
      BIT_MAPPING.FORCE_TO_USE_WEBRTC.default
    );

    if (
      optionForceToUseWebRTC &
      BIT_MAPPING.FORCE_TO_USE_WEBRTC.candidates.AUDIO_ON_BROWSER_32BIT
    ) {
      if (check32bitChrome) {
        return true;
      }
    }

    let info = this.getOSInfo();
    //if can't get info, use the old logic
    if (!info || !info.os || !info.browser) {
      return (
        (navigator.hardwareConcurrency <= 4 ||
          !this.isSupportSharedArrayBuffer()) &&
        (1 << 5) & opResult
      );
    }
    //not desktop, use webrtc
    if (info.osType !== 0) return (1 << 5) & opResult;
    //not support SAB, use webrtc default
    if (!this.isSupportSharedArrayBuffer()) return (1 << 5) & opResult;
    //not Mac/Windows/Chrome OS, use webrtc solution
    if (
      info.os !== 'Windows' &&
      info.os !== 'Mac OS' &&
      info.os !== 'Chromium OS'
    ) {
      return (1 << 5) & opResult;
    }

    //Chrome/safari/edge use wasm solution if cores > 4
    if (
      (info.browser.name === 'Chrome' ||
        info.browser.name === 'Safari' ||
        info.browser.name === 'Edge') &&
      navigator.hardwareConcurrency > 4
    )
      return (1 << 6) & opResult;
    return (1 << 5) & opResult;
  },
  requestScreenWakeLock() {
    return screenWakeLock.requestWakeLock();
  },
  releaseScreenWakeLock() {
    return screenWakeLock.release();
  },
  isSupportPeerConnection() {
    if (typeof RTCPeerConnection === 'function') {
      return true;
    }
    return false;
  },

  async initAsyncJob() {
    check32bitChrome = !!(await this.is32bitChrome());
  },

  isSupportVideoProcessor(mediaFeatureOptions) {
    const mediaProcessorConfig = ABOptionsReader.read(
      mediaFeatureOptions,
      BIT_MAPPING.ENABLE_MEDIA_PROCESSOR.index,
      1,
      BIT_MAPPING.ENABLE_MEDIA_PROCESSOR.default
    );

    const isVideoProcessorEnabled = !!(
      mediaProcessorConfig &
      BIT_MAPPING.ENABLE_MEDIA_PROCESSOR.candidates.VIDEO_PROCESSOR
    );
    return (
      this.isSupportOffscreenCanvas() &&
      this.isSupportVideoFrame() &&
      isVideoProcessorEnabled
    );
  },

  isVideoProcessorEnabled(config) {
    return !!(
      config & BIT_MAPPING.ENABLE_MEDIA_PROCESSOR.candidates.VIDEO_PROCESSOR
    );
  },

  isMediaProcessorEnabled(config) {
    return config > 0;
  },
};
export default util;

export function Deferred() {
  let that = this;
  this.promise = new Promise(function (resolve, reject) {
    that.reject = reject;
    that.resolve = resolve;
  });
}

export function getFilename(url) {
  return url.split('/').pop().split('?')[0].split('#')[0];
}

/**
 * Subresource Integrity (SRI) is a security feature that enables browsers to verify that resources
 * they fetch (for example, from a CDN) are delivered without unexpected manipulation.
 * @link https://developer.mozilla.org/en-US/docs/Web/Security/Subresource_Integrity
 */
export class IntegrityHelper {
  /**
   * @param scriptURL {string}
   * @param lateLoadedAssetsHash {{}}
   */
  constructor(scriptURL, lateLoadedAssetsHash) {
    this.scriptURL = scriptURL;
    this.lateLoadedAssetsHash = lateLoadedAssetsHash;
  }

  /**
   * @returns {string|null}
   */
  getIntegrity() {
    let filename = getFilename(this.scriptURL);
    let integrity = this.lateLoadedAssetsHash[filename];
    if (integrity) {
      return integrity;
    } else {
      return null;
    }
  }
}

export function getGcd(a, b) {
  let max = Math.max(a, b);
  let min = Math.min(a, b);
  if (max % min === 0) {
    return min;
  } else {
    return getGcd(max % min, min);
  }
}

export function getLcm(a, b) {
  return (a * b) / getGcd(a, b);
}

export function AdjustRegion_AspectRatio(
  ratioWidth,
  ratioHeight,
  alignX,
  alignY,
  alignWidth,
  alignHeight,
  urc
) {
  if (0 == ratioWidth || 0 == ratioHeight) return false;
  if (0 != alignWidth) {
    let lcm = getLcm(ratioWidth, alignWidth);
    let m = lcm / ratioWidth;
    ratioWidth = lcm;
    ratioHeight *= m;
  }
  if (0 != alignHeight) {
    let lcm = getLcm(ratioHeight, alignHeight);
    let m = lcm / ratioHeight;
    ratioHeight = lcm;
    ratioWidth *= m;
  }
  if (urc.width < ratioWidth || urc.height < ratioHeight) return false;

  let iw = urc.width / ratioWidth;
  let ih = urc.height / ratioHeight;
  let factor = iw < ih ? iw : ih;
  let ulAdjustedWidth = ratioWidth * factor;
  let ulAdjustedHeight = ratioHeight * factor;
  let ulAdjustedX = urc.x + (urc.width - ulAdjustedWidth) / 2;
  let ulAdjustedY = urc.y + (urc.height - ulAdjustedHeight) / 2;
  if (0 != alignX) ulAdjustedX -= ulAdjustedX % alignX;
  if (0 != alignY) ulAdjustedY -= ulAdjustedY % alignY;
  let urcAdjusted = {};
  urcAdjusted.left = ulAdjustedX;
  urcAdjusted.top = ulAdjustedY;
  urcAdjusted.width = ulAdjustedWidth;
  urcAdjusted.height = ulAdjustedHeight;
  return urcAdjusted;
}

export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  } else if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (var prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false;
      }
    }
    return true;
  }

  // Private
  function isObject(obj) {
    if (typeof obj === 'object' && obj != null) {
      return true;
    } else {
      return false;
    }
  }
}

export function getWMSCModule() {
  return import(/* webpackChunkName: "wmsc" */ '../inside/wmsc/WMSCManager');
}

export function getAnnoterModule() {
  return import(
    /* webpackChunkName: "annoter"*/ '../inside/annoter/annotationMgr'
  );
}

export function addSelfViewDebugInfoForWebRTC(stream, config, selfVideoDomMap) {
  if (!stream) return;
  if (!config.userId) return;

  const { userId, webrtcflag } = config;
  if (!selfVideoDomMap) return;
  if (!webrtcflag || selfVideoDomMap?.size <= 0) return;

  selfVideoDomMap.forEach((value, key) => {
    const videoDom = value;
    const id = `self_view_debug_${key}`;

    if (videoDom) {
      // videoDom.setAttribute('controls', '');
      const container = videoDom.parentNode;
      if (!container) return;

      const videoTrack = stream.getVideoTracks()[0];
      const { width = 0, height = 0 } = videoTrack.getSettings();
      const itemArray = [
        {
          elementId: 's_u_' + userId,
          value: `userId: ${userId}`,
        },
        {
          elementId: 's_r_' + userId,
          value: `stream resolutions: ${width}x${height}`,
        },
        {
          elementId: 's_a_' + userId,
          value: `stream active: ${stream.active}`,
        },
        {
          elementId: 's_m_' + userId,
          value: `video track muted: ${videoTrack.muted}`,
        },
        {
          elementId: 's_p_' + userId,
          value: `video tag paused: ${!!videoDom.paused}`,
        },
        {
          elementId: 's_sr_' + userId,
          value: `video tag src: ${!!videoDom.srcObject}`,
        },
      ];

      const debugDom = container.querySelector(`#${id}`);
      if (!debugDom) {
        videoDom.insertAdjacentHTML(
          'afterend',
          `<div id="${id}" style="position: absolute; top: 50%; transform: translate(0, -50%); right: 0px; padding-right:3px"></div>`
        );
      } else {
        if (debugDom.nextElementSibling) {
          container.removeChild(debugDom.nextElementSibling);
        }

        itemArray.reverse().forEach((item) => {
          const { elementId, value } = item;
          const element = container.querySelector(`#${elementId}`);
          if (!element) {
            debugDom.insertAdjacentHTML(
              'afterbegin',
              `<div id="${elementId}" style="color: red; font-size: 12px; text-align: right; margin-right: 5px">${value}</div>`
            );
          } else {
            if (element.innerText !== value) {
              element.innerText = value;
            }
          }
        });
      }
    } else {
      const element = document.getElementById(id);
      if (element) {
        const parent = element.parentNode;
        parent.removeChild(element);
      }
    }
  });
}

export function addUserEventListener(callback) {
  const b = document.body;
  const events = [
    'click',
    'contextmenu',
    'auxclick',
    'dblclick',
    'mousedown',
    'mouseup',
    'touchend',
    'keydown',
    'keyup',
  ];
  function unlock() {
    callback();
  }

  events.forEach((e) => b.addEventListener(e, unlock, false));
  return function clean() {
    events.forEach((e) => b.removeEventListener(e, unlock));
  };
}

export function VideoStreamCanCapture(stream) {
  let tracks = stream?.getVideoTracks();

  if (!tracks?.length) {
    return false;
  }
  return !tracks[0].muted;
}

export function RecordVideoState(videodom) {
  try {
    let [track] = videodom.srcObject?.getVideoTracks() || [];
    let status = `VDom:${videodom.ended}:${videodom.paused}:${videodom.readyState},VTrack:${track?.muted}:${track?.readyState}`;
    Zoom_Monitor.add_monitor(status);
  } catch (e) {
    globalTracingLogger.error('RecordVideoState error', e);
  }
}

export function CheckCanvasSize(width, height) {
  if (width < 0 || height < 0 || width > 3000 || height > 3000) {
    globalTracingLogger.directReport(`CANVASSIZE:${width}x${height}`);
  }
}

export function isHtmlElement(el) {
  if (!el) {
    return false;
  }
  if (el.ownerDocument === window.document) {
    return el instanceof HTMLElement;
  } else {
    const frameElement = el.ownerDocument.defaultView?.HTMLElement;
    return (
      (!!frameElement && el instanceof frameElement) ||
      el instanceof HTMLElement
    );
  }
}

export function parseDOMParams(param) {
  const result = {
    dom: null,
    id: '',
  };
  if (param) {
    if (typeof param === 'string') {
      result.dom = document.getElementById(param);
      result.id = param;
    } else if (isHtmlElement(param)) {
      result.dom = param;
      result.id = param?.getAttribute?.('id');
    }
  }
  return result;
}

export function checkBrowserVersion() {
  try {
    const MIN_VERSION_INFO = {
      safari: '15.0',
      firefox: '89',
      chrome: '91',
      edge: '91',
      opera: '77',
    };

    const PERFORMANCE_VERSION_INFO = {
      safari: '16.4',
      firefox: '105',
      chrome: '102',
      edge: '102',
      opera: '88',
    };

    let browser = Object.assign(
      { name: '', version: '' },
      getOSVersionInfo().browser
    );

    let idx = Object.keys(MIN_VERSION_INFO).findIndex((elm) => {
      if (browser.name.toLowerCase().includes(elm)) {
        browser.name = elm;
        return true;
      }
    });

    if (idx == -1) {
      console.error(
        '!!!!! For a better experience, please use Chrome (version >= 102) or Safari (version >= 16.4) browser '
      );
      globalTracingLogger.error(`unkown browser info`);
      return;
    }

    if (!versionCompare(browser.version, MIN_VERSION_INFO[browser.name])) {
      console.error(
        `!!!! For a better experience,  \n \
      Chrome (version > ${MIN_VERSION_INFO.chrome} ,performance version > ${PERFORMANCE_VERSION_INFO.chrome}) \n \
      Safari (version > ${MIN_VERSION_INFO.safari} , performance version > ${PERFORMANCE_VERSION_INFO.safari}) \n \
      Firefox (version > ${MIN_VERSION_INFO.firefox} , performance version > ${PERFORMANCE_VERSION_INFO.firefox}) \n \
      Edge (version > ${MIN_VERSION_INFO.edge} , performance version > ${PERFORMANCE_VERSION_INFO.edge} ) \n \
      Opera (version > ${MIN_VERSION_INFO.opera} , performance version > ${PERFORMANCE_VERSION_INFO.opera}) \n`
      );

      globalTracingLogger.error(`less than min version `);
    }
  } catch (e) {
    globalTracingLogger.error('check browser version error', e);
  }
}

export function replaceComma(str, optional = '|') {
  if (!str) return '';
  return str.toString().replaceAll(/[,，]/g, optional);
}

export function isChromeOS() {
  return /\bCrOS\b/.test(navigator.userAgent);
}

export function isMobileDevice() {
  if (
    navigator.userAgent.match(/Android/i) || // tablet, phone, mrt-a
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    /iPad/i.test(navigator.userAgent) ||
    (/Macintosh/i.test(navigator.userAgent) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
}

export function isTablet() {
  if (isChromeOS()) {
    return false;
  }
  const userAgent = (navigator?.userAgent || '').toLowerCase();
  const tabletRegex = /ipad|android(?!.*mobile)|tablet/i;

  if (tabletRegex.test(userAgent)) {
    return true;
  }

  const minTabletWidth = 600;
  const maxTabletWidth = 1280;

  const screenWidth = Math.min(
    window?.screen?.width || 0,
    window?.screen?.height || 0
  );

  const hasTouchSupport =
    'ontouchstart' in window || navigator.maxTouchPoints > 0;

  return (
    screenWidth >= minTabletWidth &&
    screenWidth <= maxTabletWidth &&
    hasTouchSupport
  );
}

export function isWebGLAvailable() {
  try {
    const canvas = document.createElement('canvas');
    const gl =
      canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    return !!gl;
  } catch (e) {
    return false;
  }
}

export function createRlbSocket(url) {
  const ZoomTPModule = self.ZoomTPModule;
  const WS =
    ZoomTPModule && ZoomTPModule.ZoomTPWebSocket
      ? ZoomTPModule.ZoomTPWebSocket
      : WebSocket;
  return new WS(url);
}

export function setIsWebRTCMode(value = false) {
  isWebRTCMode = value;
}
let audioProbResult = true;
export function updateAudioProbResult() {
  try {
    let audioProb = new PersistenceInfo().getAudioSolutionInfo();
    audioProbResult = audioProb?.getProbResult();
  } catch (e) {
    globalTracingLogger.error('updateAudioProbResult error', e);
    audioProbResult = true;
  }
}

export function isMacIntel() {
  return util.isMacIntelSafari() || util.isMacIntelChrome();
}
updateAudioProbResult();

export function IsSupportTransferableDataChannel() {
  try {
    if (browserType?.browser === 'safari' && isSafariVersionHigherThan(15))
      return true;
    if (browserType?.browser === 'chrome' && isChromeVersionHigherThan(131))
      return true;
    return false;
  } catch (e) {
    return false;
  }
}
