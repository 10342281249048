export const WEBGPU_CAP = {
  AVAILABLE: 0,
  NOT_SUPPORTED: 1,
  CANNOT_REQ_ADAPTER: 2,
  CANNOT_REQ_DEVICE: 3,
};

export const RENDERER_TYPE = {
  AUTO: -1,
  UNDEFINED: 0,
  WEBGL: 1,
  WEBGPU: 2,
  WEBGL_2: 3,
};

export const WEBRTC_STG = {
  DISABLED: 0, // Cannot use WebRTC as rendering method, maybe hit the blacklist
  ENABLED: 1, // Use WebRTC as rendering method
  AUTO: 2, // default to use WebRTC if get the capability of WebRTC
};

export const WEBRTC_STG_ERRNO = {
  SUCCEED: 0,
  UNKNOWN: -1,
  INVALID_ARG: -2,
  BROWSER_NOT_SPT: -3,
  DEVICE_ON_BLACKLIST: -4,
  INVALID_DEVICE_INFO: -5,
  UNKNOWN_SELECTION: -6,
  OTHER_EX: -7,
  DEVICE_NOT_ON_WHITELIST: -8,
  FEATURE_OPTION_DISABLED: -9,
};

export const SERVE_FOR = {
  AVAILABLE: 0,
  VIDEO: 1,
  SHARE: 2,
};

/**
 * Rendering states describes the state of how a backend renderer renders.
 * Basically, the process of states changing will be: IDLE -> PENDING -> READY -> RENDERING -> PENDING -> IDLE
 */
export const RENDERING_STATE = {
  IDLE: 0, // idle state means the attached render display is not in-use
  PENDING: 1, // pending state means the render display is in used now, but waits for the next rendering data
  READY: 2, // ready state means the rending data is ready to be rendered, like GPU textures
  RENDERING: 3, // rendering state means the render display is in rendering, all rendering commands will dispatch to GPU side
};

export const TEX_LAYER_TYPE = {
  UNKNOWN: -1,
  BASE_LAYER: 0,
  BLEND_LAYER: 1,
};

export const TEX_TYPE = {
  UNKNOWN: -1,
  EXTERNAL_TEX: 0,
  GPU_TEX_YUV: 1,
  GPU_TEX_RGBA: 2,
  CLEAR_COLOR: 3,
};

export const ROTATION_CLOCK_0 = 0;
export const ROTATION_CLOCK_90 = 1;
export const ROTATION_CLOCK_180 = 2;
export const ROTATION_CLOCK_270 = 3;

export const BASIC_UV_COORD_ARRAY = [
  { u: 1.0, v: 0.0 },
  { u: 1.0, v: 1.0 },
  { u: 0.0, v: 1.0 },
  { u: 1.0, v: 0.0 },
  { u: 0.0, v: 0.0 },
  { u: 0.0, v: 1.0 },
];

export const BASIC_VTX_COORD_ARRAY = [
  { x: 1.0, y: 1.0 },
  { x: 1.0, y: -1.0 },
  { x: -1.0, y: -1.0 },
  { x: 1.0, y: 1.0 },
  { x: -1.0, y: 1.0 },
  { x: -1.0, y: -1.0 },
];

export const RENDER_DATA_TYPE = {
  INVALID: -1,
  VIDEO_FRAME: 0,
  YUV: 1,
  RGBA: 2,
};

export const TEX_LAYER_Z_IDX = {
  VS_BASE: 0,
  CURSOR: 1,
  WATERMARK: 2,
  MASK: 3,
  END: 4,
};

// all possible vendors are : 'intel', 'nvidia', 'amd', 'apple', 'qualcomm', 'arm', 'mediatek'
export const GPU_VENDOR_WHITELIST = [
  'intel',
  'nvidia',
  'apple',
  'amd',
  'qualcomm',
  'arm',
];

export const VIDEO_FRAME_SHADER = /* wgsl */ `
      struct VertexOutput {
          @builtin(position) Position: vec4<f32>,
          @location(0) uv: vec2<f32>,
      };
  
      struct FsUniforms {
          rotation: f32,
      };
  
      @group(0) @binding(0) var vfSampler: sampler;
      @group(0) @binding(1) var vfTexture: texture_external;
      @group(0) @binding(2) var<uniform> vertexUniforms: FsUniforms;
      
      @vertex
      fn vertex_main(
          @builtin(vertex_index) VertexIndex: u32,
          @location(0) vtxPos: vec2<f32>,
          @location(1) uvPos: vec2<f32>
      ) -> VertexOutput {
      
          var output: VertexOutput;
          output.Position = vec4<f32>(vtxPos, 0.0, 1.0);
          
          if (vertexUniforms.rotation == 0) {
              output.uv = vec2f(uvPos.x, 1 - uvPos.y);    
          } else if (vertexUniforms.rotation == 1) {
              output.uv = vec2f(1 - uvPos.y, 1 - uvPos.x);
          } else if (vertexUniforms.rotation == 2) {
              output.uv = vec2f(uvPos.x, uvPos.y);
          } else if (vertexUniforms.rotation == 3) {
              output.uv = vec2f(uvPos.y, uvPos.x);
          } else {
              output.uv = uvPos;
          }
  
          return output;
      }
      
      @fragment
      fn fragment_main(@location(0) uv : vec2<f32>) -> @location(0) vec4<f32> {
          var color: vec4<f32> = textureSampleBaseClampToEdge(vfTexture, vfSampler, uv);
          return color;
      }
  `;

export const VIDEO_YUV_I420_SHADER = /* wgsl */ `
      struct VertexOutput {
          @builtin(position) Position: vec4<f32>,
          @location(0) uv: vec2<f32>,
      };
  
      @group(0) @binding(6) var<uniform> vertexUniforms: FsUniforms;
  
      @vertex
      fn vertex_main(
          @builtin(vertex_index) VertexIndex: u32,
          @location(0) vtxPos: vec2<f32>,
          @location(1) uvPos: vec2<f32>
      ) -> VertexOutput {
      
          var output: VertexOutput;
          output.Position = vec4<f32>(vtxPos, 0.0, 1.0);
  
          if (vertexUniforms.rotation == 0) {
              output.uv = vec2f(uvPos.x, 1 - uvPos.y);    
          } else if (vertexUniforms.rotation == 1) {
              output.uv = vec2f(1 - uvPos.y, 1 - uvPos.x);
          } else if (vertexUniforms.rotation == 2) {
              output.uv = vec2f(uvPos.x, uvPos.y);
          } else if (vertexUniforms.rotation == 3) {
              output.uv = vec2f(uvPos.y, uvPos.x);
          } else {
              output.uv = uvPos;
          }
          
          return output;
      }
  
      struct FsUniforms {
          yuvMode: f32,
          colorRange: f32,
          rotation: f32,
      };
      
      @group(0) @binding(0) var yPlaneSampler: sampler;
      @group(0) @binding(1) var uvPlaneSampler: sampler;
      @group(0) @binding(2) var yPlaneTex: texture_2d<f32>;
      @group(0) @binding(3) var uPlaneTex: texture_2d<f32>;
      @group(0) @binding(4) var vPlaneTex: texture_2d<f32>;
      @group(0) @binding(5) var<uniform> uniforms: FsUniforms;
      // @group(0) @binding(7) var<storage, read_write> outputBuffer: array<f32>;
      
      @fragment
      fn fragment_main(@location(0) uv : vec2<f32>) -> @location(0) vec4<f32> {
          let y = textureSampleBaseClampToEdge(yPlaneTex, yPlaneSampler, uv).r;
          var u: f32;
          var v: f32;
          if (uniforms.yuvMode == 1) {
              u = textureSampleBaseClampToEdge(uPlaneTex, uvPlaneSampler, uv).r;
              v = textureSampleBaseClampToEdge(vPlaneTex, uvPlaneSampler, uv).r;
          } else {
              u = textureSampleBaseClampToEdge(uPlaneTex, uvPlaneSampler, uv).r;
              v = textureSampleBaseClampToEdge(uPlaneTex, uvPlaneSampler, uv).a;
          }
  
          const yuv2RGB_L = mat4x4(
              1.1643835616, 0, 1.7927410714, -0.9729450750,
              1.1643835616, -0.2132486143, -0.5329093286, 0.3014826655,
              1.1643835616, 2.1124017857, 0, -1.1334022179,
              0, 0, 0, 1
          );
  
          const yuv2RGB_F = mat4x4(
              1.0, 0, 1.402, -.701,
              1.0, -.34413, -.71414, .529135,
              1.0, 1.772, 0, -.886,
              0, 0, 0, 1
          );
  
          var color = vec4<f32>(y, u, v, 1.0);
          if (uniforms.colorRange == 0) {
              color = color * yuv2RGB_L;
          } else {
              color = color * yuv2RGB_F;
          }
  
          return color;
      }
  `;

export const VIDEO_YUV_NV12_SHADER = /* wgsl */ `
      struct VertexOutput {
          @builtin(position) Position: vec4<f32>,
          @location(0) uv: vec2<f32>,
      };
  
      @group(0) @binding(5) var<uniform> vertexUniforms: FsUniforms;
      @vertex
      fn vertex_main(
          @builtin(vertex_index) VertexIndex: u32,
          @location(0) vtxPos: vec2<f32>,
          @location(1) uvPos: vec2<f32>
      ) -> VertexOutput {
      
          var output: VertexOutput;
          output.Position = vec4<f32>(vtxPos, 0.0, 1.0);
          
          if (vertexUniforms.rotation == 0) {
              output.uv = vec2f(uvPos.x, 1 - uvPos.y);    
          } else if (vertexUniforms.rotation == 1) {
              output.uv = vec2f(1 - uvPos.y, 1 - uvPos.x);
          } else if (vertexUniforms.rotation == 2) {
              output.uv = vec2f(uvPos.x, uvPos.y);
          } else if (vertexUniforms.rotation == 3) {
              output.uv = vec2f(uvPos.y, uvPos.x);
          } else {
              output.uv = uvPos;
          }
              
          return output;
      }
  
      struct FsUniforms {
          yuvMode: f32,
          colorRange: f32,
          rotation: f32,
      };
      
      @group(0) @binding(0) var yPlaneSampler: sampler;
      @group(0) @binding(1) var uvPlaneSampler: sampler;
      @group(0) @binding(2) var yPlaneTex: texture_2d<f32>;
      @group(0) @binding(3) var uPlaneTex: texture_2d<f32>;
      @group(0) @binding(4) var<uniform> uniforms: FsUniforms;
      // @group(0) @binding(5) var<storage, read_write> outputBuffer: array<f32>;
      
      @fragment
      fn fragment_main(@location(0) uv : vec2<f32>) -> @location(0) vec4<f32> {
          let y = textureSampleBaseClampToEdge(yPlaneTex, yPlaneSampler, uv).r;
          var u: f32;
          var v: f32;
          u = textureSampleBaseClampToEdge(uPlaneTex, uvPlaneSampler, uv).r;
          v = textureSampleBaseClampToEdge(uPlaneTex, uvPlaneSampler, uv).g;
  
          const yuv2RGB_L = mat4x4(
              1.1643835616, 0, 1.7927410714, -0.9729450750,
              1.1643835616, -0.2132486143, -0.5329093286, 0.3014826655,
              1.1643835616, 2.1124017857, 0, -1.1334022179,
              0, 0, 0, 1
          );
  
          const yuv2RGB_F = mat4x4(
              1.0, 0, 1.402, -.701,
              1.0, -.34413, -.71414, .529135,
              1.0, 1.772, 0, -.886,
              0, 0, 0, 1
          );
  
          var color = vec4<f32>(y, u, v, 1.0);
          if (uniforms.colorRange == 0) {
              color = color * yuv2RGB_L;
          } else {
              color = color * yuv2RGB_F;
          }
  
          // outputBuffer[0] = y;
          // outputBuffer[1] = u;
          // outputBuffer[2] = v;
          // outputBuffer[3] = color.r;
          // outputBuffer[4] = color.g;
          // outputBuffer[5] = color.b;
          // outputBuffer[6] = color.a;
  
          return color;
      }
  `;

export const WATERMARK_SHADER = /* wgsl */ `
      @group(0) @binding(0) var watermarkSampler: sampler;
      @group(0) @binding(1) var watermarkTex: texture_2d<f32>;
  
      struct VertexOutput {
          @builtin(position) Position: vec4<f32>,
          @location(0) uv: vec2<f32>,
      };
  
      @vertex
      fn v_main(
          @builtin(vertex_index) VertexIndex: u32,
          @location(0) pos: vec2<f32>,
          @location(1) uvPos: vec2<f32>
      ) -> VertexOutput {
  
          var output: VertexOutput;
          output.Position = vec4<f32>(pos, 0.0, 1.0);
          output.uv = vec2f(uvPos.x, uvPos.y);
          return output;
      }
  
      @fragment
      fn f_main(@location(0) uv: vec2<f32>) -> @location(0) vec4<f32> {
          var color: vec4<f32> = textureSampleBaseClampToEdge(watermarkTex, watermarkSampler, uv);
          if (color.r == 0 && color.g == 0 && color.b == 0) {
              color.a = 0;
          }
          return color;
      }
  `;

export const MULTISAMPLE_TEX_SHADER = /* wgsl */ `
      @group(0) @binding(0) var mSampler: sampler;
      @group(0) @binding(1) var mTex: texture_2d<f32>;
  
      struct VertexOutput {
          @builtin(position) Position: vec4<f32>,
          @location(0) uv: vec2<f32>,
      };
  
      @vertex
      fn v_main(
          @builtin(vertex_index) VertexIndex: u32,
          @location(0) pos: vec2<f32>,
          @location(1) uvPos: vec2<f32>
      ) -> VertexOutput {
  
          var output: VertexOutput;
          output.Position = vec4<f32>(pos, 0.0, 1.0);
          output.uv = uvPos;
          return output;
      }
  
      @fragment
      fn f_main(@location(0) uv: vec2<f32>) -> @location(0) vec4<f32> {
          return textureSample(mTex, mSampler, uv);
      }
  `;

export const CURSOR_SHADER = /* wgsl */ `
  
      struct FsUniforms {
          cursorFlag: f32,
          cursorInfo: vec4f
      };
  
      @group(0) @binding(0) var cursorSampler: sampler;
      @group(0) @binding(1) var cursorTex: texture_2d<f32>;
      @group(0) @binding(2) var<uniform> uniforms: FsUniforms;
  
      struct VertexOutput {
          @builtin(position) Position: vec4<f32>,
          @location(0) uv: vec2<f32>,
      };
  
      @vertex
      fn v_main(
          @builtin(vertex_index) VertexIndex: u32,
          @location(0) pos: vec2<f32>,
          @location(1) uvPos: vec2<f32>
      ) -> VertexOutput {
  
          var output: VertexOutput;
          output.Position = vec4<f32>(pos, 0.0, 1.0);
          output.uv = vec2f(uvPos.x, 1 - uvPos.y);
          return output;
      }
  
      @fragment
      fn f_main(@location(0) uv: vec2<f32>) -> @location(0) vec4<f32> {
          var color: vec4<f32> = textureSampleBaseClampToEdge(cursorTex, cursorSampler, uv);
          // if (uniforms.cursorFlag == 1) {
          //     if (uniforms.cursorInfo.z > 0.0 
          //         && uv.x >= uniforms.cursorInfo.x
          //         && uv.y >= uniforms.cursorInfo.y
          //         && uv.x < uniforms.cursorInfo.x + uniforms.cursorInfo.z
          //         && uv.y < uniforms.cursorInfo.y + uniforms.cursorInfo.w) {
  
          //         var cursorCoord: vec2f = uv - uniforms.cursorInfo.xy;
          //         cursorCoord = cursorCoord / uniforms.cursorInfo.zw;
          //         var cursorColor: vec4<f32> = textureSampleBaseClampToEdge(cursorTex, cursorSampler, cursorCoord);
          //         color = color * (1.0 - cursorColor.a) + cursorColor * cursorColor.a;
          //     }
          // }
  
          return color;
      }
  `;

export const VIDEO_TEX_LOAD_SHADER = /* wgsl */ `
      struct VertexOutput {
          @builtin(position) Position: vec4<f32>,
          @location(0) uv: vec2<f32>,
      };
      
      @vertex
      fn vertex_main(
          @builtin(vertex_index) VertexIndex: u32,
          @location(0) vtxPos: vec2<f32>,
          @location(1) uvPos: vec2<f32>
      ) -> VertexOutput {
      
          var output: VertexOutput;
          output.Position = vec4<f32>(vtxPos, 0.0, 1.0);
          output.uv = uvPos;
          return output;
      }
      
      struct FsArgs {
          onlyRgba: f32,
          bgraMode: f32,
          colorRange: f32,
          watermarkFlag: f32,
          cursorFlag: f32,
          maskFlag: f32,
          yuvMode: f32,
          cursorInfo: vec4f
      };
  
      @group(0) @binding(0) var vfSampler: sampler;
      @group(0) @binding(1) var vfTexture: texture_external;
      @group(0) @binding(2) var<uniform> fsArgs: FsArgs;
      
      @fragment
      fn fragment_main(@location(0) uv : vec2<f32>) -> @location(0) vec4<f32> {
          let x: u32 = u32(uv[0]);
          let y: u32 = u32(uv[1]);
          var u32_uv = vec2<u32>(x, y);
          return textureLoad(vfTexture, u32_uv);
      }
  `;

export const CLEAR_SHADER = /*wgsl*/ `
      @vertex
      fn v_main(
          @builtin(vertex_index) VertexIndex: u32,
          @location(0) vtxPos: vec2<f32>,
      ) -> @builtin(position) vec4<f32> {
          return vec4<f32>(vtxPos, 0.0, 1.0);
      }
      
      @fragment
      fn f_main() -> @location(0) vec4<f32> {
          return vec4(0.0, 0.0, 0.0, 1.0);
      }
  `;

export const CLEAR_COLOR_SHADER = /*wgsl*/ `
      @vertex
      fn v_main(
          @builtin(vertex_index) VertexIndex: u32,
          @location(0) vtxPos: vec2<f32>,
      ) -> @builtin(position) vec4<f32> {
          return vec4<f32>(vtxPos, 0.0, 1.0);
      }
  
      struct ClearColorUniforms {
          clearColor: vec4<f32>,
      };
  
      @group(0) @binding(0) var<uniform> uniforms: ClearColorUniforms;
      @fragment
      fn f_main() -> @location(0) vec4<f32> {
          return uniforms.clearColor;
      }
  `;

export const DM_VERTEX_SHADER = `
      attribute vec2 aPosition;
      attribute vec2 aTexCoord;
      varying vec2 vTexCoord;
      void main() {
        gl_Position = vec4(aPosition.x, -aPosition.y, 0.0, 1.0);
        vTexCoord = aTexCoord;
      }
  `;

export const DM_FRAGMENT_SHADER = `
      precision mediump float;
      varying vec2 vTexCoord;
      uniform sampler2D uVideoTexture;
      uniform sampler2D uMaskTexture;
      uniform vec4 uEllipses[10];
      uniform float uAngles[10]; 
      uniform int uEllipseCount;
  
      void main() {
          vec4 videoColor = texture2D(uVideoTexture, vTexCoord); 
          vec4 maskColor = texture2D(uMaskTexture, vTexCoord);
  
          bool inEllipse = false;
          for (int i = 0; i < 10; i++) {
              if (i >= uEllipseCount) break;
  
              vec2 ellipseCenter = uEllipses[i].xy;
              vec2 ellipseRadii = uEllipses[i].zw;
              float angle = radians(uAngles[i]); 
              vec2 pos = vTexCoord - ellipseCenter;
  
              vec2 rotatedPos;
              rotatedPos.x = pos.x * cos(angle) + pos.y * sin(angle);
              rotatedPos.y = -pos.x * sin(angle) + pos.y * cos(angle);
   
              float ellipseEq = (rotatedPos.x * rotatedPos.x) / (ellipseRadii.x * ellipseRadii.x) +
                              (rotatedPos.y * rotatedPos.y) / (ellipseRadii.y * ellipseRadii.y);
  
              if (ellipseEq <= 1.0) {
              inEllipse = true;
              break;
              }
          }
  
          gl_FragColor = inEllipse ? videoColor : maskColor;
      }
  `;

export const GPU_RES_TYPE = {
  TEXTURE_BUFFER: 0,
  VERTEX_BUFFER: 1,
  TEXTURE: 2,
};

export const RES_OCCUPANCY_LEVEL = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
  OVERUSE: 3,
};

export const RES_RECYCLE_INTERVAL = {
  LOW: 60000,
  MEDIUM: 45000,
  HIGH: 30000,
  OVERUSE: 15000,
};

export const RESOLUTION_LEVELS = [
  60,
  120,
  180, // the small resolutions,
  360,
  540,
  720, // the middle range resolutions,
  1080,
  2160, // the big range resolutions,
];

export const RENDER_PIPELINE_TYPE = {
  VIDEO_FRAME: 0,
  YUV_I420: 1,
  YUV_NV12: 2,
  RGBA_WATERMARK: 3,
  RGBA_CURSOR: 4,
  CLEAR_COLOR: 5,
};

export const LOW_RSL_LEVEL_OFFSET = 0;
export const MED_RSL_LEVEL_OFFSET = 3;
export const HIG_RSL_LEVEL_OFFSET = 6;
export const TEXTURE_MIP_LEVELS = [180, 360, 540, 720, 1080, 2160];
export const MAX_GAP_PIXELS = 5;
