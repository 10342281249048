import { VideoProcessorEvent } from './ProcessorEvents';

export interface InputStream {
  setStream(stream: MediaStream): void;
  removeStream(): void;
  destroy?(): void;
}

export class TrackProcessorInputStream implements InputStream {
  constructor(
    private port: MessagePort,
    private name: string,
    private VideoFrameNeeded: boolean
  ) {}
  private trackProcessor: MediaStreamTrackProcessor<VideoFrame>;
  setStream(stream: MediaStream) {
    const videoTrack = stream.getVideoTracks()[0];
    const videoTrackSetting = videoTrack.getSettings();
    const { frameRate = 24 } = videoTrackSetting;
    this.trackProcessor = new MediaStreamTrackProcessor({
      track: videoTrack,
    });
    const readableStream = this.trackProcessor.readable;
    this.port.postMessage(
      {
        cmd: VideoProcessorEvent.INPUT_READABLE_STREAM,
        frameRate: Math.min(frameRate, 24),
        readableStream,
        processorName: this.name,
      },
      [readableStream]
    );

    this.port.postMessage({
      cmd: VideoProcessorEvent.START_REQUEST_FRAME,
      frameRate,
      processorName: this.name,
      VideoFrameNeeded: this.VideoFrameNeeded,
    });
  }
  removeStream() {
    this.port.postMessage({
      cmd: VideoProcessorEvent.STOP_REQUEST_FRAME,
      processorName: this.name,
    });
  }
}

export class CanvasCaptureInputStream implements InputStream {
  private captureVideoEle: HTMLVideoElement;
  private captureVideoEleLoaded: boolean;
  private captureCanvasEle: HTMLCanvasElement;
  private captureCanvasEleCtx: CanvasRenderingContext2D;

  private onMessage = (e: MessageEvent) => {
    if (e.data.cmd === VideoProcessorEvent.REQUEST_FRAME) {
      if (this.captureVideoEleLoaded) {
        let width = this.captureVideoEle.videoWidth;
        let height = this.captureVideoEle.videoHeight;
        if (width <= 0 || height <= 0) {
          return;
        }
        if (this.isIOSDevice) {
          this.captureCanvasEle.width = width;
          this.captureCanvasEle.height = height;

          this.captureCanvasEleCtx.drawImage(
            this.captureVideoEle,
            0,
            0,
            width,
            height
          );
        }

        const frame = new VideoFrame(
          this.isIOSDevice ? this.captureCanvasEle : this.captureVideoEle,
          { timestamp: performance.now() * 1000 }
        );
        this.port.postMessage({
          cmd: VideoProcessorEvent.FRAME_DATA,
          frame,
          processorName: this.name,
        });
        frame.close();
      }
    }
  };

  constructor(
    private port: MessagePort,
    private name: string,
    private isIOSDevice: boolean = false,
    private VideoFrameNeeded: boolean = false
  ) {
    this.port.addEventListener('message', this.onMessage);
  }
  private createCaptureVideoElement(stream: MediaStream) {
    this.captureVideoEle = document.querySelector(`#video-processor-video`);
    if (!this.captureVideoEle) {
      this.captureVideoEle = document.createElement('video');
      this.captureVideoEle.autoplay = true;
      this.captureVideoEle.playsInline = true;
      this.captureVideoEle.id = 'video-processor-video';
      this.captureVideoEle.style.position = 'fixed';
      this.captureVideoEle.style.width = '1px';
      this.captureVideoEle.style.height = '1px';
      this.captureVideoEle.style.bottom = '0px';
      this.captureVideoEle.style.right = '0px';
      this.captureVideoEle.muted = true;
      document.body.appendChild(this.captureVideoEle);
    }
    if (this.isIOSDevice && !this.captureCanvasEle) {
      this.captureCanvasEle = document.createElement('canvas');
      this.captureCanvasEleCtx = this.captureCanvasEle.getContext('2d');
    }
    this.captureVideoEle.srcObject = stream;
    this.captureVideoEle.addEventListener('loadedmetadata', () => {
      this.captureVideoEleLoaded = true;
    });
    this.captureVideoEle.play();
  }
  setStream(stream: MediaStream) {
    const videoTrack = stream.getVideoTracks()[0];
    const videoTrackSetting = videoTrack.getSettings();
    const { frameRate = 24 } = videoTrackSetting;
    this.createCaptureVideoElement(stream);
    this.port.postMessage({
      cmd: VideoProcessorEvent.START_REQUEST_FRAME,
      frameRate: Math.min(frameRate, 24),
      processorName: this.name,
      VideoFrameNeeded: this.VideoFrameNeeded,
    });
  }
  removeStream() {
    this.port.postMessage({
      cmd: VideoProcessorEvent.STOP_REQUEST_FRAME,
      processorName: this.name,
    });
  }
  destroy(): void {
    this.port.removeEventListener('message', this.onMessage);
  }
}
